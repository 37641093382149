<template lang="">
    <div id="slogan3" class="animContainer">
        <div class="w-100 d-flex flex-wrap justify-content-center container-data animation">
            <div class="col-lg-6 col-12">
                <div class="w-100 flex-wrap text-2 text-start line-1 text-13 fw-bold font-1 mb-0 mb-lg-5 mt-4 mt-lg-0"> 
                    Entrenador<br>Personalizado
                </div>
                <div class="w-100 flex-wrap text-12 fw-bold font-1 text-start line-2 my-lg-5 mt-md-5 mt-4 mb-0 color-14">
                    Aseguramos la integración exitosa de cada proyecto.
                </div>
                <div class="w-100 d-flex mt-4 px-1 px-lg-4 pb-4 mt-lg-5 mt-4">
                    <div class="col-2 d-flex justify-content-center align-items-start">
                        <img :src="a11" class="icon1 user-select-none pe-none">
                    </div> 
                    <div class="col-10 col-lg-10 d-flex flex-wrap px-md-4 mt-lg-3 pb-2 pb-lg-5">
                        <div class="text-11 line-4 font-1 w-100 text-start ps-2 ps-md-4 mt-3 color-13">Sabemos lo importante que es comenzar bien.<br><br>Es por eso que te asignamos a un experto para integrar Onico a tu consultorio.<br><br>Te capacitamos, brindamos soporte y damos seguimiento de forma semanal durante tu primer trimestre.</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-12 d-flex flex-wrap justify-content-center align-items-start align-content-start pt-lg-5">
                <div class="col-9 squareOnboarding">
                    <img :src="a18" class="col-10 imagen1 pe-none user-select-none">
                </div>
                <div class="w-100 flex-wrap text-11 font-1 text-center line-4 mt-4 mt-lg-5 color-13">
                    <span class="fw-bold color-14">Julián Concha</span><br>Experto en Onboardings
                </div>
            </div>
        </div>
        <div class="my-5 d-flex justify-content-center w-100 animation">
            <button class="text-6 font-1 contact me-1 me-md-4" data-bs-toggle="modal" data-bs-target="#contactModal">&nbsp;&nbsp;&nbsp;&nbsp;Contáctanos&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
            <button class="text-6 font-1 schedule ms-1ms-md-4" openScheduler="0">Agenda una demo</button>
        </div>
    </div>
</template>
<script>
export default {
    name:"slogan3",
    data(){
        return{
            a11: require('@/assets/images/a11.png'),
            a18: require('@/assets/images/a18.jpg'),
        }
    },
    mounted(){
    },
    methods:{
    }
}
</script>
<style lang="css" scoped>
    #slogan3{
        width:100%;
        height:auto;
        position: relative;
        overflow: hidden;
        margin-top: 15%;
    }
    .container-data {
        background-color: var(--c1);
        padding: 0 4% 0 4%;
    }
    .line-1{
        color: var(--sexagenary);
        line-height: 1;
    }
    .line-2{
        color: var(--secondary);
        line-height: 1.2;
    }
    .icon1{
        width: 80px;
    }
    .imagen1{
        border-radius: 30px; 
        object-fit: cover; 
        width: 100%;
        height: 100%;
    }
    .squareOnboarding{
        height: 550px;
        overflow: hidden;
    }

    @media (min-width: 0px) and (max-width: 549px) {
        .squareOnboarding{
            height: 250px;
            overflow: hidden;
        }
    }
    @media (min-width: 550px) and (max-width: 799px) {
        .squareOnboarding{
            height: 380px;
            overflow: hidden;
        }
    
    }
    @media (min-width: 800px) and (max-width: 991px) {
        .squareOnboarding{
            height: 500px;
            overflow: hidden;
        }
    
    }
    @media (min-width: 992px) and (max-width: 1244px) {
        .squareOnboarding{
            height: 300px;
            overflow: hidden;
        }
    
    }
    @media (min-width: 1245px) and (max-width: 1699px) {
        .squareOnboarding{
            height: 400px;
            overflow: hidden;
        }
    
    }
    @media (min-width: 1700px){
        .squareOnboarding{
            height: 450px;
            overflow: hidden;
        }
    
    }
    @media (min-width: 0px) and (max-width: 992px) {

        .icon1{
            width: 40px !important;
        }

        .line-1{
            line-height: 1.3;
        }

        .line-2{
            line-height: 1.4;
        }
    }

</style>