<template lang="">
    <div class="componentAllContainer overflow-hidden">
        <div class="insideContainer w-100 h-100 d-flex">

            <div :class="loged ? 'col-12' : 'col-4 ' " class="l-side h-100 d-flex justify-content-center align-items-center align-content-center pl-4 d-flex">
                <img :src="logo">
            </div>

            <div :class="loged ? 'col-0' : 'col-4 ' "  class="col-8 r-side h-100 p-0 m-0 d-flex align-items-center align-content-center flex-wrap">

                <div class="row p-0 w-100">
                    <div class="col-5 text-end d-flex align-items-center justify-content-end font-1">
                        Licencia
                    </div>
                    <div class="col-7 d-flex justify-content-start">
                        <input v-model="user" class="font-1 inputLogin" @change="this.error=false" :class="error ? 'error' : ''" type="text">
                    </div>
                </div>

                <div class="row p-0 w-100 mt-4">
                    <div class="col-5 text-end d-flex align-items-center justify-content-end font-1">
                        Keypass
                    </div>
                    <div class="col-7 d-flex justify-content-start">
                        <input v-model="pass" class="font-1 inputLogin" @change="this.error=false" :class="error ? 'error' : ''" type="password">
                    </div>
                </div>

                <div class="row p-0 w-100 mt-4">
                    <div class="col-12 d-flex justify-content-center">
                        <button class="font-1 logButton" @click="checkLoging()">Acceder</button>
                    </div>
                </div>
               
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"login",
    emits:['loged'],
    data(){
        return{
            logo:require('@/assets/images/logo1.png'),
            pass:"pintimambafrida",
            user:"soporte",
            error:false,
            loged:false
        }
    },
    methods:{
        checkLoging(){
            if(this.user == "soporte" && this.pass == "pintimambafrida"){
                this.loged=true;
                setTimeout(()=>{
                    this.$emit('loged', true);
                }, 1100);
            }else{
                this.error=true;
            }
        }
    }
}
</script>
<style lang="css" scoped>
    .componentAllContainer{
        width: 80%;
        height:90%;
        background-color: transparent;
        border-radius: 30px;
        position: absolute;
        top:5%;
        left: 10%;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    }

    .insideContainer{

    }

    .l-side{
        background-color: var(--cuartenary);
        -webkit-transition: width 0.5s ease-in-out;
        -moz-transition: width 0.5s ease-in-out;
        -o-transition: width 0.5s ease-in-out;
        transition: width 0.5s ease-in-out;
    }

    .l-side img{
        object-fit: contain;
        width: 60%;
        max-width: 400px;
    }

    .r-side{
        background-color: var(--c1);
    }

    .inputLogin{
        width: 50%;
        height: 40px;
        border-radius: 7px;
        padding: 0 10px 0 10px;
    }

    .inputLogin.error{
        border: 1px solid var(--secondary);
    }

    .inputLogin:not(.error){
        border: 1px solid var(--sexagenary);
    }
    
    .logButton {
      align-items: center;
      background-color: var(--secondary);
      color: var(--c1);
      border: 0 solid #E2E8F0;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      box-sizing: border-box;
      display: inline-flex;
      font-family: Inter, sans-serif;
      font-size: 1rem;
      font-weight: 700;
      height: 56px;
      justify-content: center;
      line-height: 24px;
      overflow-wrap: break-word;
      padding: 24px;
      text-decoration: none;
      width: auto;
      border-radius: 8px;
      cursor: pointer;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
    }

.logButton:focus {
  box-shadow: var(--secondary)  0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, var(--secondary)  0 -3px 0 inset;
}

.logButton:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, var(--secondary) 0 -3px 0 inset;
  transform: translateY(-2px);
}

.logButton:active {
  box-shadow: var(--secondary)  0 3px 7px inset;
  transform: translateY(2px);
}
</style>