<template lang="html">
    
    <div id="error" class="d-flex w-100 flex-wrap justify-content-center align-items-center align-content-center">

        <top-menu></top-menu>

        <div id="cover">
            <div class="position-relative w-100 h-100">
                <ul class="background">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
        </div>

        <div class="innerError d-flex w-100 flex-wrap justify-content-center align-items-center align-content-center pt-0 pb-5">
        
            <div class="w-100 txtErr d-flex justify-content-center align-items-center align-content-center">4<span class="imgErr">0</span>4</div>
            <div class="errorLabel w-100 text-1 color-7 text-center font-1 color-1 fw-bold my-4">
                Ooops!
            </div>
            <div class="w-100 textLabel text-6 text-center font-1 color-2">
                Página no encontrada
            </div>
        </div>

        <div class="w-100 links">
            <pie :flinks="flinks"></pie>
        </div>
    </div>
</template>
<script>

import Pie from '@/components/home/Pie.vue'

import TopMenu from '@/components/home/Menu.vue'
export default {
    name: "NotFound",
    props:{
        con: {
            default: {},
            type: Object
        },
        flinks: {
            default: {},
            type: Object
        },
    },
    mounted(){
        this.$scrollInTop();
    },
    components:{
        TopMenu,
        Pie
    },
    data() {
        return {
            a8: require("@/assets/images/a8.png"),
        };
    },
};
</script>
<style lang="css">
    #cover{
        width: 100%;
        height:100%;
        position: relative;
        left: 0;
        top:0;
        z-index: 9;
    }
    #error {
        width: 100%;
        min-height: 100vh;
        overflow: hidden;
    }
    .innerError{
        min-height: calc(100vh - 70px);
    }
    .links{
        z-index: 100000;
    }
    .textLabel{
        z-index: 13;
    }
    .imgContent {
        color: transparent;
        position: relative;
        overflow: visible;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
    }
    .imgErr {
        height: auto;
        width: auto;
        opacity: 0;
        animation: errorAppend 0.5s linear 0.5s 1 forwards;
        z-index: 10;
    }
    .txtErr {
        font-size: 180px;
        height: 300px;
        line-height: 0;
        z-index: 11;
        color:var(--secondary)
    }
    .errorLabel {
        animation: error 10s infinite linear;
        z-index: 12;
    }
    @keyframes error {
        0% {
            transform: translateY(0px);
        }
        25% {
            transform: translateY(-10px);
        }
        50% {
            transform: translateY(0px);
        }
        75% {
            transform: translateY(10px);
        }
        100% {
            transform: translateY(0px);
        }
    }
    @keyframes errorAppend {
        0% {
            opacity: 0;
            transform: scale(10);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }
    @keyframes animate {
        0%{
            transform: translateY(0) rotate(0deg);
            opacity: 1;
            border-radius: 0;
        }
        100%{
            transform: translateY(-1000px) rotate(720deg);
            opacity: 0;
            border-radius: 50%;
        }
    }

    .background {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        background: #ffffff;
        overflow: hidden;
    }
    .background li {
        position: absolute;
        display: block;
        list-style: none;
        width: 15px;
        height: 15px;
        background: rgb(242, 85, 90, 0.5);
        animation: animate 10s linear infinite;
    }


    .background li:nth-child(0) {
        left: 47%;
        width: 103px;
        height: 103px;
        bottom: -103px;
        animation-delay: 1s;
    }
    .background li:nth-child(1) {
        left: 40%;
        width: 196px;
        height: 196px;
        bottom: -196px;
        animation-delay: 1s;
    }
    .background li:nth-child(2) {
        left: 86%;
        width: 121px;
        height: 121px;
        bottom: -121px;
        animation-delay: 10s;
    }
    .background li:nth-child(3) {
        left: 10%;
        width: 170px;
        height: 170px;
        bottom: -170px;
        animation-delay: 14s;
    }
    .background li:nth-child(4) {
        left: 10%;
        width: 177px;
        height: 177px;
        bottom: -177px;
        animation-delay: 18s;
    }
    .background li:nth-child(5) {
        left: 4%;
        width: 163px;
        height: 163px;
        bottom: -163px;
        animation-delay: 2s;
    }
    .background li:nth-child(6) {
        left: 69%;
        width: 111px;
        height: 111px;
        bottom: -111px;
        animation-delay: 15s;
    }
    .background li:nth-child(7) {
        left: 1%;
        width: 151px;
        height: 151px;
        bottom: -151px;
        animation-delay: 2s;
    }
    .background li:nth-child(8) {
        left: 50%;
        width: 121px;
        height: 121px;
        bottom: -121px;
        animation-delay: 7s;
    }
    .background li:nth-child(9) {
        left: 64%;
        width: 167px;
        height: 167px;
        bottom: -167px;
        animation-delay: 6s;
    }
</style>