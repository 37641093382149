<template>
    <div class="container my-5">
        <div class="d-flex flex-wrap px-0 w-100 justify-content-center">
            <div class="col-12 col-md-6 col-lg-4 mb-4 px-sm-5 px-2 px-md-2 px-xl-4" v-for="(card, index) in iterateData" :key="index">
                <a :href="card.linksrc" class="card h-100">
                    <div class="imageRep">
                        <img :src="processImage(card.picture)" class="card-img-top w-100" :alt="card.title">
                    </div>
                    <div class="card-body pb-md-5 pb-3 mt-2 mt-md-0">
                        <h5 class="card-title font-6 fw-bold text-start t-color-2 lh-3 | t-16 t-sm-8 t-md-7 t-lg-8 t-xl-7 t-xxl-5">{{ card.title }}</h5>
                        <p class="card-text font-5 text-start t-color-c3 lh-4 | t-12 t-sm-6 t-md-5 t-lg-6 t-xl-5 t-xxl-3 | mt-4">{{ card.description }}</p>
                    </div>
                    <div class="card-footer bg-transparent border-0 px-3 text-start pb-4 d-flex align-content-stretch align-items-stretch">
                        <a :href="card.linksrc" class="font-6 fw-bold text-start t-color-2 lh-3 | t-14 t-sm-7 t-md-6 t-lg-7 t-xl-6 t-xxl-4 h-100 d-flex align-items-start align-content-start">{{ card.linktext }}</a>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>

import { xmlToJson, getAutoUrl } from '@/assets/js/utils.js';

export default {
    name: "landingCards",
    props: {
        content: {
            default: "",
            type: String
        }
    },
    data() {
        return {
            pos: 2,
            transite: 'append',
            activeTransite: true,
            iterateData: [],
            domain:this.$route.name == 'landingTest' ? 'https://draft10.efisense.xyz/landings/assets': 'https://d1tlwjnil29m1k.cloudfront.net/onico/landings/assets',
            defData: [
                {
                    picture: require('@/assets/images/a34.webp'),
                    title: 'Activa una prueba gratuita, descarga Onico y conócelo.',
                    description: '¿Aún tienes dudas? Activa tu prueba gratuita y descubre todo lo que Onico puede hacer por ti.',
                    linktext: 'Explora Onico con una prueba gratuita.',
                    linksrc: '/'
                },
                {
                    picture: require('@/assets/images/a33.webp'),
                    title: 'Ahorra tiempo con nuestras funciones especiales',
                    description: 'Desde la gestión de citas hasta el seguimiento de pacientes, Onico Health te ofrece herramientas que simplifican tu trabajo diario.',
                    linktext: 'Aprende más sobre nuestro proceso de Onboarding',
                    linksrc: '/'
                },
                {
                    picture: require('@/assets/images/a32.webp'),
                    title: 'Descubre cómo gestionar tus imágenes médicas eficientemente.',
                    description: 'Optimiza la gestión de tus imágenes médicas con nuestras avanzadas herramientas.',
                    linktext: 'Aprende más sobre el módulo de imágenes.',
                    linksrc: '/'
                }
            ]
        }
    },
    mounted() {
        this.render();
    },
    methods: {
        processImage(image) {
            return getAutoUrl(image, this.domain)
        },
        render() {
            if (this.content != null && this.content != '') {
                
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(this.content, "text/xml");
                const jsonResult = xmlToJson(xmlDoc.documentElement);
                if(jsonResult.hasOwnProperty('element')){
                    if (Array.isArray(jsonResult.element)) {
                        this.iterateData = jsonResult.element;
                    } else if (typeof jsonResult.element === 'object' && jsonResult.element !== null) {
                       this.iterateData.push(jsonResult.element)
                    }
                }else{
                    console.log('la personalización del elemento importado no tiene elementos')
                }
                
            } else {
                this.iterateData = this.defData;
            }
        },
        setPos(pos) {
            this.activeTransite = false;
            this.transite = false;
            setTimeout(() => {
                this.transite = 'append';
                this.pos = pos;
            }, 100);
        },
    }
}
</script>
<style lang="css" scoped>

.card-img-top{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
    border-radius: 0;
    
}

.imageRep{
    width: 100%;
    height: 200px;
    border-bottom: 1px solid rgba(50, 50, 93, 0.05);
}

a{
    text-decoration: none;
}

.card{
    border-radius: 0;
    border: 1px solid #AFAFAF;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.card:hover .card-title, .card:hover a{
    color:var(--octagenary)
}

.card:hover img{
    opacity: 0.9;
}

.card:hover{
    box-shadow: rgba(255, 86, 96, 0.25) 0px 2px 5px -1px, rgba(255, 86, 96, 0.3) 0px 1px 3px -1px;
}

@media (min-width: 0px) and (max-width: 575px) {
    .imageRep{
        height: 150px;
    }
}


</style>