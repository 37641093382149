<template>
    <nav class="navbar navbar-expand-lg sticky-top navbar-dark p-0 m-0 w-100">
        <div class="container-fluid m-0 p-0">

            <div class="col-5 d-flex d-lg-none flex-wrap mt-0 justify-content-start ps-lg-5 ps-3 py-sm-2 py-lg-0 font-1" @click="goto('portada')">
                <img :src="a9" class="logo" >
             </div>

            <button class="navbar-toggler my-3 me-4" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText"
                aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse mb-4 mb-lg-0 mt-0 top-navbar py-3" id="navbarText">

                <div class="w-100 d-flex flex-wrap mt-2 mt-lg-0 justify-content-center justify-content-lg-end px-lg-3 align-items-center">

                    <div class="col-3 d-none d-lg-flex flex-wrap mt-2 mt-lg-0 justify-content-start ps-lg-5 font-1" @click="goto('portada')">
                        <img :src="a9" class="logo">
                    </div>

                    <div class="col-lg-9 col-12 d-flex flex-wrap mt-2 mt-lg-0 justify-content-start justify-content-lg-end pe-lg-5 font-1">

                        <div class="d-flex col-6 col-lg-auto d-flex">
                            <div class="nav-item m-0 py-4 px-4 col-4 d-inline-flex justify-content-center w-100">
                                <a class="nav-link active text-7-1 menuElement font-1" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" @click="goto('portada')">ONICO EMR</a>
                            </div>
                        </div>
                        <div class="d-flex col-6 col-lg-auto">
                            <div class="nav-item m-0 py-4 px-4 col-4 d-inline-flex justify-content-center w-100">
                                <a class="nav-link text-7-1 menuElement" href="/l/precios">PRECIOS</a>
                            </div>
                        </div>
                        <div class="d-flex col-6 col-lg-auto ">
                            <div class="nav-item m-0 py-4 px-4 col-4 d-inline-flex justify-content-center w-100">
                                <a class="nav-link text-7-1 menuElement" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" @click="goto('slogan6')">CONTACTO</a>
                            </div>
                        </div>
                        <div class="d-flex col-6 col-lg-auto ">
                            <div class="nav-item m-0 py-4 px-4 col-4 d-inline-flex justify-content-center w-100">
                                <a class="nav-link text-7-1 menuElement" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" @click="gotoRoute('downloads')">DESCARGAR</a>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </div>

    </nav>
</template>
<script>

import { Navbar } from 'bootstrap'

export default {
    name: "menutop",
    data(){
        return{
            a9:require('@/assets/images/a9.png')
        }
    },
    methods:{
        gotoRoute(route){
            window.location.replace("/"+route);
        },
        goto(id){
            try{
                if(this.$route.name != "NotFound" && this.$route.name=='home'){
                    let element = document.getElementById(id);
                    let headerOffset = 0;

                    if(window.innerWidth < 768){
                        headerOffset = 120;
                    }else{
                        headerOffset = 160;
                    }

                    let elementPosition = element.getBoundingClientRect().top;
                    let offsetPosition = elementPosition + window.scrollY - headerOffset;
                    
                    window.scrollTo({
                        top: offsetPosition,
                        behavior: "instant"
                    });
                }else{
                    window.location.replace("/#"+id);
                }
            }catch(err){}
        }
    }
}
</script>

<style lang="css" scoped>

.navbar{
    background-color: var(--secondary);
    color: var(--c1);
    display: flex;
}
.top-navbar{
    background-color: var(--secondary);
}

.menuElement {
    height: 100%;
    text-decoration: none;
    color: var(--c1);
    font-weight: bold;
    cursor: pointer !important;
}

.nav-link:hover {
    opacity: 0.7;
    color:var(--tertiary)
}

.logo{
    object-fit: contain;
    height: 100%;
    width: 100%;
    object-position: center left;
    max-height: 70px;
    padding: 0px 5px;
}
</style>