<template lang="">
    
    <div id="panel" class="componentAllContainer overflow-hidden font-1">

        <new-user-modal v-if="newUser" @close="closeNewUser"></new-user-modal>

        <div class="insideContainer w-100 h-100 d-flex">

            <div class="l-side h-100 d-flex justify-content-center align-items-start align-content-start d-flex flex-wrap ">
                <div class="w-100 text-center mt-5 we-1 text-7">
                    Panel
                </div>

                <div class="w-100 p-0 mt-5 mb-5 we-1 d-flex justify-content-center">
                    <div class="square d-flex flex-wrap align-items-center align-content-center justify-content-center">
                        <div class="w-100 color-1 text-8 px-3" @click="newUserModal()"> Añadir Nuevo Usuario</div>
                        <div class="col-6 col-lg-4 color-1 text-8 icon mt-2"> <img :src="m6" alt=""> </div>
                    </div>
                </div>

                <div class="w-100 text-center mt-2 ps-4 we-1 d-flex iconMenu active d-flex align-items-center align-content-center justify-content-start" >
                    <img :src="m5" alt="" class="iconMenu me-2 ms-3">Usuarios
                </div>

                <div class="w-100 text-center mt-2 ps-4 we-1 d-flex iconMenu d-flex align-items-center align-content-center justify-content-start" >
                    <img :src="m5" alt="" class="iconMenu me-2 ms-3">Módulos
                </div>

                <div class="w-100 text-center mt-2 ps-4 we-1 d-flex iconMenu d-flex align-items-center align-content-center justify-content-start" >
                    <img :src="m5" alt="" class="iconMenu me-2 ms-3">Estadisticas
                </div>

            </div>

            <div class="r-side h-100 p-0 m-0 d-flex justify-content-end align-items-start align-content-start flex-wrap">

                <div class="m-l-side h-100 p-0 m-0 d-flex align-items-start align-content-center flex-wrap justify-content-start flex-column" v-if="selectedUser == 0">

                    <div class="color-2 text-7 mt-4 rounded-title">LISTA DE USUARIOS EN LA LICENCIA</div>

                    <div class="color-1 text-7 mt-4 content-space dflex d-flex align-items-start align-content-start flex-wrap justify-content-start pt-4">
                        <div v-for="(user, uid) in users" class="userElement" @click="getUserConf(uid+1)">
                            <div class="w-100">
                                <img :src="m7" alt="">
                            </div>
                            <div class="w-100 color-1 mt-2 text-8">
                                {{user.name}} {{uid+1}}
                            </div>
                        </div>
                    </div>

                </div>

                <div class="m-l-side h-100 p-0 m-0 d-flex align-items-start align-content-center flex-wrap justify-content-start flex-column" v-else>

                    <div class="color-2 text-7 mt-4 rounded-title d-flex align-items-center align-content-center justify-content-between px-3">
                        <span>
                            <button class="button-simple" role="button" @click="listUsers();">Regresar</button>
                        </span>
                        <span class="d-block">CONFIGURACIÓN DE USUARIO: {{selectedUser}}</span>
                        <span>
                            <button class="button-simple" role="button" @click="getData()">Guardar</button>
                        </span>
                    </div>

                    <div class="color-1 text-7 mt-4 content-space dflex d-flex align-items-start align-content-start flex-wrap justify-content-start p-0">
                        <user-conf :userID="selectedUser"></user-conf>
                    </div>

                </div>

                <div class="m-r-side h-100 p-0 m-0 d-flex align-items-start align-content-start flex-wrap justify-content-start flex-wrap">

                    <div class="w-100 p-0 mt-5 we-1 d-flex justify-content-center">
                        <div class="square d-flex flex-wrap align-items-center align-content-center justify-content-center overflow-hidden p-1">
                            <img :src="e1" alt="" class="assetRes roundedRes">
                        </div>
                    </div>

                    <div class="w-100 text-center mt-5 ps-3 we-1 d-flex d-flex align-items-center align-content-center justify-content-start text-7" >
                        Información
                    </div>

                    <div class="w-100 text-center mt-5 px-2 d-flex d-flex align-items-center align-content-center justify-content-start" >
                        
                        <table class="table">
                            <thead>
                            </thead>
                            <tbody>
                              <tr>
                                <td class="text-start align-middle text-8">Licencia</td>
                                <td class="text-start align-middle text-8">778</td>
                              </tr>
                              <tr>
                                <td class="text-start align-middle text-8">Antiguedad de licencia</td>
                                <td class="text-start text-8">755 dias</td>
                              </tr>
                              <tr>
                                <td class="text-start align-middle text-8">Tiempo restante de licencia</td>
                                <td class="text-start align-middle text-8">285 dias</td>
                              </tr>
                              <tr>
                                <td class="text-start align-middle text-8">Usuarios</td>
                                <td class="text-start align-middle text-8">25</td>
                              </tr>
                              <tr>
                                <td class="text-start align-middle text-8">Zona</td>
                                <td class="text-start align-middle text-8">gmt-5</td>
                              </tr>
                            </tbody>
                        </table>

                    </div>
                    
    
                </div>

            </div>
        </div>

    </div>
</template>
<script>

import userConf from '@/components/umanagement/UserConf.vue'
import newUserModal from '@/components/umanagement/NewUserModal.vue'

export default {
    name:"login",
    emits:['loged'],
    components:{
        userConf,
        newUserModal
    },
    data(){
        return{
            e1:require('@/assets/images/example-logo.jpg'),
            m5:require('@/assets/images/m5.png'),
            m6:require('@/assets/images/m6.png'),
            m7:require('@/assets/images/m7.png'),
            users:[
                {name:"usuario"},
                {name:"usuario"},
                {name:"usuario"},
                {name:"usuario"},
                {name:"usuario"},
                {name:"usuario"},
                {name:"usuario"},
                {name:"usuario"},
                {name:"usuario"},
                {name:"usuario"}
            ],
            selectedUser:0,
            newUser:false,
        }
    },
    methods:{
        closeNewUser(){
            this.newUser = false;
        },
        newUserModal(){
            this.newUser = true;
        },
        checkLoging(){
            if(this.user == "soporte" && this.pass == "pintimambafrida"){
                this.$emit('loged', true)
            }
        },
        getUserConf(id){
            this.selectedUser = id;
        },
        listUsers(){
            this.selectedUser = 0;
        },
        getData(){
            let json = {};
            let jsonIntern = {};
            let jsonInternData = {};

            json.userId=this.selectedUser;
            json.modules = [];

            document.querySelectorAll('.conf-module').forEach((module)=>{
                jsonIntern = {};
                jsonIntern.name = module.getAttribute('data');
                jsonIntern.data = [];

                module.querySelectorAll('.conf-intern').forEach((conf)=>{

                    jsonInternData = {};

                    conf.querySelectorAll('.input-data').forEach((data)=>{
                        if(data.type == "text"){
                            jsonInternData[data.getAttribute('label')] = data.value;
                        }else if(data.type == "hidden"){
                            jsonInternData.id = data.value;
                        }else if(data.type == "checkbox"){
                            jsonInternData.trash = data.checked ? 1 : 0 ;
                        }
                    });

                    jsonIntern.data.push(jsonInternData);

                });

                json.modules.push(jsonIntern);
            })

            console.log(json)
        }
    }
}
</script>
<style lang="css" scoped>
    .componentAllContainer{
        width: 80%;
        height:90%;
        background-color: transparent;
        border-radius: 30px;
        position: absolute;
        top:5%;
        left: 10%;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    }

    .insideContainer{
        background-color: var(--c1);
    }


    .l-side{
        background-color: var(--c1);
        width: 200px;
    }

    .r-side{
        background-color: var(--cuartenary);
        width: auto;
        flex: 1;
        border-radius: 35px 0 0 35px;
        overflow: hidden;
    }

    .m-l-side{
        width: auto;
        flex: 1;
    }

    .m-r-side{
        background-color: var(--c1);
        width: 300px;
        border-radius: 35px 0 0 35px;
    }

    .square{
        width: 150px;
        height: 150px;
        background-color: var(--cuartenary);
        border-radius: 25px;
        cursor: pointer;
    }

    .square:hover{
        opacity: 0.7;
    }

    .icon img{
        object-fit: cover;
        width: 35px;
    }

    .iconMenu{
        padding: 10px 0 10px 0;
        cursor: pointer;
    }

    .iconMenu img{
        width: 22px;
        height: auto;
    }

    .iconMenu.active{
        background-color: var(--cuartenary);
        color:var(--c1);
    }

    .iconMenu:hover{
        background-color: var(--primary);
        color:var(--c1)
    }

    .rounded-title{
        background-color: var(--c1);
        border-radius: 25px;
        padding: 1em 0 1em 0;
        width: 90%;
    }

    .content-space{
        width: 90%;
        flex: 1;
        overflow: auto;
        margin-bottom: 20px;
    }

    #panel .content-space>* {
        color: var(--septagenary)
    }

    #panel .content-space::-webkit-scrollbar {
        background-color: transparent;
        width: 8px;
    }

    #panel .content-space::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        box-shadow: inset 0 0 0 10px;
        border-radius: 3px;
    }

    #panel .content-space:hover {
        color: rgba(241, 87, 83, 0.8);
    }

    .userElement{
        width: 100px;
        height: auto;
        cursor: pointer;
    }

    .userElement img{
        width: 50%;
        height: auto;
    }

    .userElement:hover{
       opacity: 0.5;
    }


    .button-simple {
        background-color: #FFFFFF;
        border: 1px solid #222222;
        border-radius: 8px;
        box-sizing: border-box;
        color: #222222;
        cursor: pointer;
        display: inline-block;
        font-family: Circular,-apple-system,BlinkMacSystemFont,Roboto,"Helvetica Neue",sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        margin: 0;
        outline: none;
        padding: 13px 23px;
        position: relative;
        text-align: center;
        text-decoration: none;
        touch-action: manipulation;
        transition: box-shadow .2s,-ms-transform .1s,-webkit-transform .1s,transform .1s;
        user-select: none;
        -webkit-user-select: none;
        width: auto;
    }

    .button-simple:focus-visible {
        box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
        transition: box-shadow .2s;
    }

    .button-simple:active {
        background-color: #F7F7F7;
        border-color: #000000;
        transform: scale(.96);
    }

    .button-simple:disabled {
        border-color: #DDDDDD;
        color: #DDDDDD;
        cursor: not-allowed;
        opacity: 1;
    }

</style>