<template lang="">
    <div id="slogan4" class="animContainer">
        <div class="modal animation" v-bind:class="{ active: isModalActive }" @click="closeModal">
            <img :src="selectedImage" v-if="selectedImage" />
        </div>
        <div class="w-100 d-flex justify-content-center container-data align-items-stretch align-content-center animation">
            <div class="col-7 d-flex flex-wrap aling-items-center align-content-center">
                <div class="w-100 flex-wrap text-2 text-start line-1 text-13 font-2 pt-5">
                    ELEVA EL NIVEL<br>DE ATENCIÓN.
                </div>
                <div class="w-100 d-flex mt-0 mt-lg-2 ps-4 pb-4">
                    <div class="col-12 d-flex flex-wrap pe-4 mt-lg-3 mt-4 mt-lg-0 pb-4 pb-lg-5">
                        <div class="text-2 color-1 ps-md-4 font-1 w-100 py-0 mt-lg-3 mt-0 mb-0 text-start">Onico está diseñado para que cada paciente se vaya con una sonrisa, gracias a un servicio que supera expectativas.</div>
                    </div>
                </div>
            </div>
            <div class="col-5 d-flex align-items-end align-content-end" @click="showModal(a16)">
                <img :src="a16" class="w-100 imagen1 user-select-none pe-none">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"slogan4",
    data(){
        return{
            a16:require('@/assets/images/a16.png'),
            selectedImage: null,
            isModalActive: false,
        }
    },
    mounted(){
    },
    methods:{
        showModal(image) {
          this.selectedImage = image;
          this.isModalActive = true;
        },
        closeModal() {
          this.isModalActive = false;
          this.selectedImage = null;
        }
    }
}
</script>
<style lang="css" scoped>
    #slogan4{
        width:100%;
        height:auto;
        position: relative;
        overflow: hidden;
        margin-top: 15%;
    }
    .container-data{
        background-color: var(--secondary);
        padding: 0 0 0 4%;
    }
    .line-1{
        color: var(--c1);
        line-height: 1;
    }
    .imagen1{
        height: 100%;
        width: 100%;
        object-position: bottom center;
        object-fit: contain;
    }

    @media (min-width: 0px) and (max-width: 992px) {

        .imagen1{
            height: 100%;;
            object-position: bottom center;
            object-fit: cover;
        }

        .line-1{
            line-height: 1.3;
        }
    }
    .modal {
        display: none;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.9);
        justify-content: center;
        align-items: flex-end;
        z-index: 2000;
    }
    .modal img {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .modal.active {
        display: flex;
    }

</style>