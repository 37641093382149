import { createApp } from 'vue'
import { createHead } from '@vueuse/head';
import App from './App.vue'
import router from './router'
import VueMatomo from 'vue-matomo'
import '@/assets/scss/globalColors.scss'
import  { request } from './/assets/js/request.js';

var generalRequest = new request(); //funciones de peticiones xhr (api)
const app = createApp(App, {con:generalRequest});

const head = createHead();

app.config.globalProperties.$scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth' // Omitir para un salto suave
    });
};
  
app.config.globalProperties.$scrollInTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'instant' // Omitir para un salto instantáneo sin animación
    });
};

app.config.globalProperties.$api = "https://ap15.efisense.net/apiOnico/";
generalRequest.apiUrl = "https://ap15.efisense.net/apiOnico/";

app.use(router);
app.use(head);
app.use(VueMatomo, {
    host: 'https://dashboard.efisense.net/analytics',
    siteId: 1,
});

app.mount('#app');
window._paq.push(['trackPageView']);