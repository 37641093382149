<template lang="">
    <div id="slogan6" class="animContainer mb-5">
        <div class="w-100 container-data mt-5 d-flex flex-wrap justify-content-center animation">
            <div class="text-12 line-1 font-1 w-100 text-center px-3 fw-bold color-5 mt-lg-5 mt-0">¿Aún tienes preguntas?</div>
            <div class="text-15 line-2 font-1 col-10 text-center px-3 color-2 mt-4">No podrás encontrar un mejor sistema para administrar la salud de tus pacientes y consultorio.</div>
            <div class="text-15 line-3 font-1 col-8 text-center px-3 color-2 mt-4">¿Tienes dudas sobre cómo Onico puede transformar tu consultorio? Nuestros especialistas están listos para asistirte por teléfono o chat.</div>
        </div>
        <div class="my-5 d-flex justify-content-center w-100">
            

            <div class="button-container">
                <button class="text-6 font-1 schedule" data-bs-toggle="modal" data-bs-target="#contactModal">CONTÁCTANOS</button>
                <div v-if="showIcons" class="icon-container mt-4">
                    <i @click="makeCall" class="iconButton phone-icon me-4">
                        <svg width="80px" height="80px" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--twemoji pe-none" preserveAspectRatio="xMidYMid meet"><path fill="#31373D" d="M34.06 26.407l-3.496-3.496a4.942 4.942 0 0 0-8.34 2.528c-5.765-1.078-11.372-6.662-11.721-11.653a4.908 4.908 0 0 0 2.586-1.36a4.943 4.943 0 0 0 0-6.99L9.594 1.94a4.943 4.943 0 0 0-6.99 0C-7.882 12.426 23.574 43.882 34.06 33.396a4.944 4.944 0 0 0 0-6.989z"></path>
                        </svg></i>
                    <i @click="sendEmail" class="iconButton email-icon ms-4">
                        <svg width="80px" height="80px" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                        <path fill="#494c4e" d="M9 12a3.14 3.14 0 0 1-1.64-.462L0 7.056V14a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V7.056l-7.354 4.48A3.148 3.148 0 0 1 9 12z"/>
                        <path fill="#494c4e" d="M17.466 5.04L9.6 9.83a1.144 1.144 0 0 1-1.2 0L.534 5.04a1.09 1.09 0 0 1-.49-1.24 1.14 1.14 0 0 1 1.09-.8h15.733a1.138 1.138 0 0 1 1.09.8 1.09 1.09 0 0 1-.49 1.24z"/>
                      </svg>
                    </i>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"slogan6",
    data(){
        return{
            a5:require('@/assets/images/a5.png'),
            showIcons: false
        }
    },
    mounted(){
    },
    methods:{
        toggleIcons() {
            this.showIcons = !this.showIcons;
        },
        makeCall() {
            window.location.href = 'tel:+529999306053';
        },
        sendEmail() {
            window.location.href = 'mailto:hola@efisense.com';
        }
    }
}
</script>
<style lang="css" scoped>
    #slogan6{
        width:100%;
        height:auto;
        position: relative;
        overflow: hidden;
        margin-top: 5%;
    }
    .container-data{
        background-color: var(--c1);
        padding: 0 4% 0 4%;
    }
    .line-1{

    }

    .icon-container {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-shrink: 0;
        display: flex;
        gap: 10px;
    }
    
    .iconButton {
        background-color: #f0f0f0;
        border: 1px solid #ccc;
        border-radius: 50%;
        width: 80px;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-shrink: 0 !important;
        display: flex;
        height: 80px;
        cursor: pointer;
        transition: background-color 0.3s;
    }
    
    .iconButton:hover {
        background-color: #ddd;
    }

    .iconButton svg{
        pointer-events: none;
        width: 70%;
        height: 70%;
        object-fit: contain;
    }

    ::v-deep(.iconButton svg path){
        fill:var(--septadecagenary)
    }
    
   

</style>