<template lang="">
    <div id="portada">
        <div class="modal" v-bind:class="{ active: isModalActive }" @click="closeModal">
            <img :src="selectedImage" v-if="selectedImage" />
        </div>
        <video width="320" height="240" id="vidDataSloaganOne" playsinline autoplay muted webkit-playsinline class="bgPortrait pe-none user-select-none" :poster="a35">
            <source :src="vidData" type="video/mp4"  preload="auto">
            Your browser does not support the video tag.
        </video>
          
        <!-- <img :src="a10" class="bgPortrait pe-none user-select-none"> -->
        <div class="bgPortraitCover"></div>
        <div class="m-0 container-data px-4 px-lg-5 d-flex align-items-center align-content-center align-content-lg-center align-items-lg-center flex-wrap mb-5">
            <div class="line-1 text-9 font-1 color-1 text-start ps-2 ps-lg-5">
                Transforma tu consultorio<br>con Onico Health.
            </div>
            <div class="w-100 text-start ps-5 mt-4">
                <div class="line-2 text-6 font-1 color-1 text-start ps-2 ps-lg-5">
                     Onico está diseñado para que puedas brindar una atención más personalizada en menos tiempo.<br>Descubre cómo con una demostración personalizada.
                </div>
            </div>
        </div>
    </div>
    <div class="lineImage w-100 position-relative" @click="showModal(a11)">
        <img :src="a11" class="floatImage user-select-none pe-none">
    </div>
</template>
<script>
export default {
    name:"portada",
    data(){
        return{
            a10:require('@/assets/images/a10.webp'),
            a11:require('@/assets/images/a11.webp'),
            a35:require('@/assets/images/a35.jpg'),
            selectedImage: null,
            isModalActive: false,
            vidData: require('@/assets/videos/portrait.mp4'),
            vid:null,
        }
    },
    mounted(){
        document.getElementById('vidDataSloaganOne').play();
    },
    methods:{
        showModal(image) {
          this.selectedImage = image;
          this.isModalActive = true;
        },
        closeModal() {
          this.isModalActive = false;
          this.selectedImage = null;
        },
        play(){
            if (this.vid.paused){
                this.vid.play(); 
            }
        },
        stop(){
            if (this.vid.play){
                this.vid.pause(); 
            }
        }
    }
}
</script>
<style lang="css" scoped>
    #portada{
        width:100%;
        height:auto;
        position: relative;
        overflow: hidden;;
        background-color: #707070;
        min-height: 900px;
    }
    #portada .container-data{
        width: 98%;
        height: 500px;
        background-color: rgba(255, 255, 255, 0.19);
        z-index: 3;
        left: 0;
        top: 230px;
        position: absolute;
    }
    .bgPortrait{
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        z-index: 1;
        display: block;
        object-fit: cover;
    }
    .bgPortraitCover{
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        z-index: 2;
        background-color: #707070;
        opacity: 0.9;
        display: block;
    }
    .line-1{
        line-height: 1.1;
    }
    .line-2{
        line-height: 1.1;
    }
    .lineImage{
        overflow: visible;
        z-index: 5;
    }
    .floatImage{
        position: absolute;
        width: 600px;
        top: -240px;
        right: 80px;
        border-radius: 14px;
        border: 2px solid var(--quinary);
    }
    @media (min-width: 768px) and (max-width: 1067px) {
        .floatImage{
            position: absolute;
            width: 55%;
            top: -145px;
            right: 10%;
        }
    }
    @media (min-width: 545px) and (max-width: 767px) {
        .floatImage{
            position: absolute;
            width: 70%;
            top: -105px;
            right: 15%;
        }
    }
    @media (min-width: 0px) and (max-width: 544px) {
        .floatImage{
            position: absolute;
            width: 90%;
            top: -170px;
            right: 5%;
        }
        .bgPortrait{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 30% center;
        }
        .bgPortraitCover{
            opacity: 0.8
        }
        #portada .container-data{
            top: 140px;
            height: 400px;
        }
        #portada{
            min-height:720px;
        }
        .line-1{
            line-height: 1.2;
        }
        .line-2{
            line-height: 1.4;
        }
    }
    @media (min-width: 545px) and (max-width: 765px) {
        .line-1{
            line-height: 1.2;
        }
        .line-2{
            line-height: 1.3;
        }
        .bgPortrait{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        #portada .container-data{
            top: 140px;
            height: 500px;
        }
        #portada{
            min-height: 750px;
        }
    }
    @media (min-width: 768px) and (max-width: 1067px) {
        .line-1{
            line-height: 1.2;
        }
        .line-2{
            line-height: 1.3;
        }
        .bgPortrait{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top center;
        }
        #portada .container-data{
            top: 180px;
            height: 340px;
        }
        #portada{
            min-height: 650px;
        }
    }
    
    .modal {
        display: none;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.9);
        justify-content: center;
        align-items: flex-end;
        z-index: 2000;
    }
    .modal img {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .modal.active {
        display: flex;
    }

</style>