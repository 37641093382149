<template>

    <div ref="content"
        class="landing d-flex flex-wrap align-items-start align-content-start justify-content-start px-0 m-0">
        <top-menu></top-menu>
        <template v-for="(element, index) in elements" :key="index">
            <component v-if="element.tagName.toLowerCase() != 'import-component'" :is="element.tagName.toLowerCase()" v-bind="extractAttributes(element)">
                <template v-for="(child, index) in element.childNodes">
                    <template v-if="child.nodeName == '#text'">
                        {{ child.textContent }}
                    </template>
                    <template v-else>
                        <DynamicComponent :content="child.outerHTML" :test="test" :con="con" @updateAnimes="updateAnimateBlocks"></DynamicComponent>
                    </template>
                </template>
            </component>
            <template v-else>
                <DynamicComponent :content="element.outerHTML" :test="test" :con="con" @updateAnimes="updateAnimateBlocks"></DynamicComponent>
            </template>
        </template>
        <pie :flinks="flinks"></pie>
        <loader :show="transiteShow" :type="transiteType" :hide="transiteHide"></loader>
    </div>


</template>

<script>

import { useHead } from '@vueuse/head';
import { updateMetaTags } from '@/assets/js/utils.js';
import TopMenu from '@/components/home/Menu.vue'
import Pie from '@/components/home/Pie.vue'
import DynamicComponent from '@/components/general/DynamicComponent.vue'
import Loader from '@/components/general/Loader.vue'

export default {
    props: {
        con: {
            default: {},
            type: Object
        },
        flinks: {
            default: {},
            type: Object
        },
    },
    data() {
        return {
            transiteShow: 0, //muestra transicion
            transiteHide: 0, //oculta transicion
            transiteType: 1, //type de transicion
            title: '',
            rawContent: '',
            elements: [],
            observer: null,
            hidden: [],
            domain:this.$route.name == 'landingTest' ? 'https://draft10.efisense.xyz/landings/assets': 'https://d1tlwjnil29m1k.cloudfront.net/onico/landings/assets',
            test:this.$route.name == 'landingTest' ? true: false
        };
    },
    components: {
        DynamicComponent,
        TopMenu,
        Pie,
        Loader
    },
    mounted() {
        this.transiteShow++;
        this.loadScript();
        this.getLandings();
        this.$scrollInTop();
    },
    watch: {
        '$route.params.id': 'getLandings',
    },
    beforeUnmount(){
        try{
            this.removeScript();
            document.querySelectorAll('.landingDefine').forEach((tag)=>{
                tag.remove();
            })
        }catch(err){}
        if (this.observer) { this.observer.disconnect();}
    },
    methods: {
        updateAnimateBlocks() {
            this.hidden = document.querySelectorAll(".animate");
            this.resetObserver();
        },
        resetObserver() {
            if (this.observer) {
                this.hidden.forEach(entry => this.observer.unobserve(entry));
                this.observer.disconnect();
            }
            this.createObserver();
        },
        createObserver() {
            const options = {
                root: null,
                rootMargin: '0%',
                threshold: 0.3
            };
            this.observer = new IntersectionObserver(this.handleIntersect, options);
            this.hidden.forEach(entry => this.observer.observe(entry));
        },
        handleIntersect(entries) {
            entries.forEach(entry => {
                entry.target.classList.add('prepare-hidden');
                if (entry.isIntersecting) {
                    entry.target.classList.add('run');
                }
            });
        },
        updateMetaTags(title=false, description=false, url=false, image=false) {
            if(title){
                useHead({
                    title,
                    meta: [
                        { property: 'og:title', content: title },
                    ],
                });
            }
            if(description){
                useHead({
                    meta: [
                        { property: 'og:description', content: description },
                    ],
                });
            }
            if(url){
                useHead({
                    meta: [
                        { property: 'og:url', content: url },
                    ],
                });
            }
            if(image){
                useHead({
                    meta: [
                        { property: 'og:image', content: image },
                    ],
                });
            }
            
        },
        loadScript() {
            const script = document.createElement('script');
            script.src = 'https://d1tlwjnil29m1k.cloudfront.net/onico/onicoSchedulerWidget.js';
            script.id = 'widget';
            document.head.appendChild(script);
        },
        removeScript() {
            const script = document.getElementById('widget');
            if (script) {
                document.head.removeChild(script);
            }
        },
        extractAttributes(element) {
            let attrs = {};
            Array.from(element.attributes).forEach(attr => {
                attrs[attr.name] = attr.value.includes('{{autodomainassets}}') 
                    ? attr.value.replace(/{{autodomainassets}}/g, this.domain)
                    : attr.value;
            });
            return attrs;
        },
        getLandings() {
            if(this.$route.params.id != null && this.$route.params.id != undefined){
                document.title = String(this.$route.params.id).replace(/[^a-z0-9 ]+/gi, "");

                this.con.getLandings([this.$route.params.id, this.test]).then((res) => {
                    if (res.code == 200 && res.data != null && res.data != false && res.data != null) {
                        if (res.data.exists) {
                            this.rawContent = res.data.content;
                            this.renderContent();
                            this.transiteHide++;
                            this.updateAnimateBlocks();
                            return true;
                        }
                    }
                    this.transiteHide++;
                    this.$router.replace({ name: 'NotFound' })
                }).catch((err) => { this.transiteHide++; this.$router.replace({ name: 'NotFound' }) })
            }else{
                this.transiteHide++;
                this.$router.replace({ name: 'NotFound' })
            }
        },
        getStyle(element) {
            const styleObject = {};
            for (let i = 0; i < element.style.length; i++) {
                const styleName = element.style[i];
                styleObject[styleName] = element.style.getPropertyValue(styleName);
            }
            return styleObject;
        },
        getChildNodes(e) {
            // Verifica si parentElement está definido
            if (encodeURI) {
                // Devuelve los childNodes como un array
                return Array.from(e.childNodes);
            }
            return [];
        },  
        setConfs(head) {
            Array.from(head.children).forEach(child => {
                switch (child.tagName.toLowerCase()) {
                    case 'title':
                        let titled = String(child.textContent).replace(/[^a-z0-9 áéíóúüñÁÉÍÓÚÜÑ]+/gi, "");
                        document.title = titled;
                        this.updateMetaTags(titled, false, 'https://draft10.efisense.xyz/'+(this.test ? 't/' : 'l/')+this.$route.params.id)
                        break;
                    case 'description':
                        this.updateMetaTags(false, String(child.textContent).replace(/[^a-z0-9 áéíóúüñÁÉÍÓÚÜÑ]+/gi, ""), false)
                        break;
                    case 'cardimage':
                        this.updateMetaTags(false, false, false, child.textContent.replace(/{{autodomainassets}}/g, this.domain))
                        break;
                    case 'style':
                        const styleTag = document.createElement('style');
                        styleTag.classList.add('landingDefine');
                        styleTag.textContent = String(child.textContent);
                        document.head.appendChild(styleTag);
                        break;
                    case 'meta':
                        const metaTag = document.createElement('meta');
                        Array.from(child.attributes).forEach(attr => {
                            metaTag.setAttribute(attr.name, attr.value);
                        });
                        document.head.appendChild(metaTag);
                        break;
                    case 'script':
                        const scriptElement = document.createElement('script');
                        scriptElement.textContent = String(child.textContent);
                        scriptElement.classList.add('landingDefine');
                        document.head.appendChild(scriptElement);
                        break;
                    default:
                        break;
                }
            });
        },
        async renderContent() {
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(this.rawContent, "text/html");

            const landingHead = xmlDoc.getElementsByTagName("landing-head")[0];
            const landingBody = xmlDoc.getElementsByTagName("landing-body")[0];


            if (landingHead && landingBody) {

                this.setConfs(landingHead)

                const childElements = Array.from(landingBody.children);
                childElements.forEach(element => {
                    this.elements.push(element);
                });

            } else {
                console.error("Los elementos landing-head y landing-body deben estar en la landing.");
                this.$router.replace({ name: 'NotFound' })
            }
        },
    },
};
</script>

<style scoped>
.landing{
    overflow-x: hidden;
}
</style>