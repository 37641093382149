<template>
    <div id="circled" class="circled">
        <div class="containerauto expanded" id="innerRoot">
            <div class="containered">
                <img id="elementIcon" :src="o" alt="">
                <img id="elementIcon2" :src="check" alt="">
                <svg class="svg-container" viewBox="0 0 200 200">
                    <circle class="slice" cx="100" cy="100" r="100"></circle>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "transitionCircle",
    props: {
        show: {
            type: Number,
            default: 0,
        },
        hide: {
            type: Number,
            default: 0,
        }
    },
    watch: {
        show() {
            this.run('show');
        },
        hide() {
            this.run('hide');
        },
    },
    mounted(){

    },
    data() {
        return {
            o: require('@/assets/images/loader/o.png'),
            nico: require('@/assets/images/loader/nico.png'),
            check: require('@/assets/images/loader/sp1.svg'),
        }
    },
    methods: {
        run(action) {
            const innerRoot = document.getElementById('innerRoot');
            const elementIcon = document.getElementById('elementIcon');
            const elementIcon2 = document.getElementById('elementIcon2');
            const circled = document.getElementById('circled');
            const slice = document.querySelector('#circled svg .slice');

            switch (true) {
                case action === 'show':
                    elementIcon.classList.add('chargeLogo');
                    elementIcon2.classList.add('chargeLogo');
                    slice.classList.add('animate');
                    break;
                case action === 'hide':
                    // Remueve las clases inmediatamente
                    elementIcon.classList.remove('chargeLogo');
                    elementIcon2.classList.remove('chargeLogo');
                    
                    // Obtén el estilo computado de la animación actual
                    const computedStyle = window.getComputedStyle(slice);
                    const dashoffset = computedStyle.getPropertyValue('stroke-dashoffset');
                    
                    // Establece el valor actual de stroke-dashoffset como una variable CSS
                    slice.style.setProperty('--current-offset', dashoffset);
                    
                    // Cambia la animación a una más rápida
                    slice.classList.remove('animate');
                    slice.classList.add('fastAnimate');

                    // Espera a que termine la animación rápida antes de ocultar el contenedor
                    setTimeout(() => {
                        circled.classList.add('hide');
                        // Resetea las clases para la próxima vez
                    }, 400); // 1000 milisegundos = 1 segundo
                    break;
            }
        }
    },
}
</script>

<style scoped>
.circled {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 3000;
    left: 0;
    top: 0;
    background-color: #F76E6E;
    clip-path: circle(0% at 0px 0px);
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    clip-path: circle(200% at 50% 50%);
}

.circled.hide{
    pointer-events: none;
}

.containerauto{
    width: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: stretch;
    align-content: stretch;
    height: 225px;
    overflow: hidden;
    margin-bottom: 20px;
}

.containered {
    position: relative;
    width: 44px; /* Ajusta según el tamaño de tu imagen */
    height: 50px; /* Ajusta según el tamaño de tu imagen */
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    transition: width 0.3s linear;
    overflow: hidden;
}
#innerRoot.expanded .containered{
    height: 225px;
    width: 200px;
}

.containerPhrase{
    height: 100%;
    width: 112px;
    opacity: 1;
    transition: opacity 0.2s ease-in;
}

#innerRoot.expanded .containerPhrase{
    width: 0px;
    opacity: 0;
}

.svg-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 3;
}

#innerRoot.expanded .svg-container {
    display: block;
}


::v-deep(.containered .slice) {
    fill: none;
    stroke: #F76E6E;
    stroke-width: 100%;
    stroke-dasharray: 628; /* Circunferencia del círculo (2 * pi * radio) */
    stroke-dashoffset: 0;
    transform:rotate(-90deg);
    transform-origin:center center;
}

::v-deep(.containered .slice.animate) {
    animation: reveal 6s linear 1 forwards;
}

::v-deep(.containered .slice.fastAnimate) {
    animation: fastReveal 0.3s 1 linear forwards; /* Acelera la animación a 1 segundo */
}

#elementIcon {
    width: 100%;
    transform-origin: 50% 50%;
    z-index: 2;
}
#elementIcon2 {
    width: 20%;
    left: 42%;
    position: absolute;
    z-index: 4;
}
#elementIcon3 {
    height: 100%;
}

.chargeLogo {
    animation: tiltOn 1s infinite ease-in-out;
}

.finishLogo {
    animation: tiltOff 0.95s 1 linear forwards;
}

.center {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 200px;
    height: 200px;
    left: calc(50vw - 100px);
    top: calc(50vh - 100px);
}

.hide {
    animation: hide 0.3s forwards;
}


@keyframes reveal {
    from {
        stroke-dashoffset: 0;
    }
    to {
        stroke-dashoffset: -628;
    }
}

@keyframes fastReveal {
    from {
        stroke-dashoffset: var(--current-offset, 0);
    }
    to {
        stroke-dashoffset: -628;
    }
}


@keyframes hide {
    0% {
        clip-path: circle(200% at 50% 50%);
    }

    100% {
        clip-path: circle(0% at 50% 50%);
    }
}

@keyframes tiltOn {
    0% {}

    50% {
        transform: scale(0.9);
        opacity: 0.2;
    }

    100% {}
}

@keyframes tiltOff {
    0% {
        opacity: 0.7
    }

    30% {
        transform: scale(1.6);
    }

    50% {
        transform: scale(1);
    }

    90% {
        transform: scale(0.4);
    }

    100% {
        opacity: 0;
    }
}


</style>