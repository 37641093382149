<template>
    <router-view v-slot="{ Component, route }">
        <transition :name="route.meta.transition" :mode="route.meta.mode" :con="con" :flinks="flinks">
            <component :is="Component" />
        </transition>
    </router-view>
</template>

<script>
export default {
    name: "index",
    props:{
        con: {
            default: {},
            type: Object
        }
    },
    data(){
        return{
            buttons:[],
            flinks:{},
        }
    },
    mounted(){
        this.con.getFooterLinks().then((res)=>{
            if(res.hasOwnProperty('data')){
                this.flinks = res.data;
            }
        }).catch((err)=>{})

        this.initializeButtons();
        this.$watch('$route', (to, from) => {
            this.cleanupButtons();
            this.$nextTick(() => {
                this.initializeButtons();
            });
         });
    },
    beforeUnmount() {
        this.cleanupButtons();
    },
    methods:{
        initializeButtons() {
            this.buttons = document.querySelectorAll('button[openScheduler]');
            this.buttons.forEach(button => {
                button.addEventListener('click', this.gtagReportConversion);
            });
        },
        cleanupButtons() {
            if (this.buttons) {
                this.buttons.forEach(button => {
                    button.removeEventListener('click', this.gtagReportConversion);
                });
            }
        },
        gtagReportConversion(event) {
            gtag('event', 'conversion', {
                'send_to': 'AW-16549069046/NDfBCKT1rLsZEPaBm9M9',
                'value': 17.0,
                'currency': 'MXN',
                'event_callback': function() {
                    console.log('Conversión registrada');
                }
            });
            return false; 
        }
    }
}
</script>


<style lang="css">
@import '@/assets/css/general.css';

#app {
    font-family: 'HelveticaNeueThin';
    font-weight: 100;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #343434;
}

.focusedError {
    display: flex;
    align-items: center;
    align-content: center;
}

.focusedError input {
    border: 5px solid red !important;
}

.focusedError::before {
    content: "";
    height: 50px;
    width: 50px;
    position: absolute;
    right: 15px;
    margin: auto;
    background-color: transparent;
    background-image: url('./assets/images/m9.png');
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1000;
    pointer-events: none;
}

.pe-7 {
    padding-right: 4rem !important;
}

/* transiciones */

.scale-enter-active,
.scale-leave-active {
    transition: all 0.1s ease;
}

.scale-enter-from,
.scale-leave-to {
    opacity: 0;
    transform: scale(0.9);
}
</style>
