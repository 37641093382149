<template lang="">
    <div class="d-flex w-100 pe-2 ps-2 py-0 mt-0">



        <div class="accordion w-100" id="confsAccordion">
            <div class="accordion-item conf-module" :data="conf.name" v-for="(conf, cid) in confs">
                <h2 class="accordion-header" :id="'heading'+cid">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse'+cid" aria-expanded="false" :aria-controls="'collapse'+cid">
                    {{conf.name}}
                    </button>
                </h2>
                <div :id="'collapse'+cid" class="accordion-collapse collapse" :aria-labelledby="'heading'+cid" data-bs-parent="#confsAccordion">

                    <div class="accordion-body d-flex flex-wrap pb-2 pt-1 px-0">

                        <div class="row w-100 confIntern mt-2 py-2 px-0 mx-2 conf-intern" v-for="(iConf, icid) in conf.data">

                            <div class="col-3 bg-danger overflow-hidden">
                                <input type="hidden" class="inputConf input-data" label="id" :value="iConf.id">
                                <input type="text" class="inputConf input-data" label="conf" :value="iConf.conf">
                            </div>
                            <div class="col-3 bg-success overflow-hidden">
                                <input type="text" class="inputConf input-data" label="value" :value="iConf.value">
                            </div>
                            <div class="col-3 bg-warning overflow-hidden">
                                <input type="text" class="inputConf input-data" label="description" :value="iConf.description">
                            </div>
                            <div class="col-3 bg-info d-flex justify-content-center overflow-hidden">

                                <div class="checkConfIntern">
                                    <div class="switch">
                                        <input :id="'confIntern'+icid" class="input input-data" label="trash" type="checkbox" :checked="iConf.trash == 1 ? true : false"/>
                                        <label :for="'confIntern'+icid" class="slider"></label>
                                    </div>
                                </div>
                                                    
                            </div>

                        </div>

                        <div class="w-100 d-flex justify-content-end px-0" @click="newRegistry(cid)">
                            <div class="col-6 col-lg-4 color-1 text-8 icon mt-2 px-0"> <img :src="m6" alt=""> </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "userConf",
    props:{
        userID:{
            default:0,
            type: Number
        }
    },
    methods:{
        newRegistry(pos){
            let newData = {
                id:null,
                conf:"",
                description:"",
                value:"",
                trash:0
            };
            this.confs[pos].data.push(newData);
        }
    },
    data(){
      return{
        m6:require('@/assets/images/m6.png'),
        confs:[
          {
            name:"profile",
            data:[
              {
                id:1771,
                conf:"shortname",
                description:"",
                value:"Soporte",
                trash:0
              },
              {
                id:1772,
                conf:"name",
                description:"",
                value:"Isabel",
                trash:1
              },
              {
                id:1773,
                conf:"lastname",
                description:"",
                value:"Lantigua",
                trash:0
              },
            ]
          },
          {
            name:"agenda",
            data:[],
          },
          {
            name:"listDoctors",
            data:[],
          },
          {
            name:"medicalhistory",
            data:[],
          },
          {
            name:"clinicaltrial",
            data:[],
          },
          {
            name:"modules",
            data:[],
          },
          {
            name:"exploration",
            data:[],
          },
          {
            name:"modulesModal",
            data:[],
          },
          {
            name:"pdf_record",
            data:[],
          },
          {
            name:"pdf_prescription",
            data:[],
          },
          {
            name:"pdf_clinical",
            data:[],
          },
          {
            name:"pdf_vaccine",
            data:[],
          },
          {
            name:"apps",
            data:[],
          },
          {
            name:"permissions",
            data:[],
          },
          {
            name:"candidates",
            data:[],
          },
          {
            name:"stores",
            data:[],
          },
          {
            name:"listDoctors",
            data:[],
          },
          {
            name:"medicalproof",
            data:[],
          }
        ]
      }
    }
}
</script>
<style lang="css">
    .confIntern{
      background-color: var(--cuartenary);
    }

    .checkConfIntern {
      --slider-height: 8px;
      --slider-width: calc(var(--slider-height) * 4);
      --switch-height: calc(var(--slider-height) * 3);
      --switch-width: var(--switch-height);
      --switch-shift: var(--slider-height);
      --transition: all 0.2s ease;
  
      --switch-on-color: #ef0460;
      --slider-on-color: #fc5d9b;
  
      --switch-off-color: #eeeeee;
      --slider-off-color: #c5c5c5;
    }
  
    .checkConfIntern .switch {
      display: block;
    }
      
    .checkConfIntern .switch .slider {
      position: relative;
      display: inline-block;
      height: var(--slider-height);
      width: var(--slider-width);
      border-radius: var(--slider-height);
      cursor: pointer;
      background: var(--slider-off-color);
      transition: var(--transition);
    }
        
    .checkConfIntern .switch .slider:after {
      background: var(--switch-off-color);
      position: absolute;
      left: calc(-1 * var(--switch-shift));
      top: calc((var(--slider-height) - var(--switch-height)) / 2);
      display: block;
      width: var(--switch-height);
      height: var(--switch-width);
      border-radius: 50%;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, .2);
      content: '';
      transition: var(--transition);
    }
      
    .checkConfIntern .switch label {
      margin-right: 7px;
    }
      
    .checkConfIntern .switch .input {
      display: none;
    }
        
    .checkConfIntern .switch .input ~ .label {
      margin-left: var(--slider-height);
    }
           
    .checkConfIntern .switch .input:checked ~ .slider:after {
      left: calc(var(--slider-width) - var(--switch-width) + var(--switch-shift));
    }
      
    .checkConfIntern .switch .input:checked ~ .slider {
      background: var(--slider-on-color);
    }
  
    .checkConfIntern .switch .input:checked ~ .slider:after {
      background: var(--switch-on-color);
    }

    .inputConf{
      width: 100%;
      color: rgb(36, 35, 42);
      font-size: 16px;
      line-height: 20px;
      min-height: 28px;
      border-radius: 4px;
      padding: 8px 16px;
      border: 2px solid transparent;
      box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;
      background: rgb(251, 251, 251);
      transition: all 0.1s ease 0s;
    }

    .inputConf:focus{
      border: 2px solid rgb(124, 138, 255);
    }

    .icon{
        cursor: pointer;
    }

    .icon:hover{
        opacity: 0.5;
    }
</style>