import axios from "axios";

class request {

	apiUrl = "";
	defToken = "s";

	settings = {}

	defSettings = {
		"url": "",
		"method": "GET",
		"timeout": 0,
	}

	constructor(api) {
		this.apiUrl = api;
	}

	jsonRequest = (req, parameter = null) => {

		this.settings = {};

		switch (req) {

			case "getLandings":
				this.settings = { ...this.defSettings };
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "website/" + (parameter[1] ? 'landingTest/' : 'landing/') + parameter[0];
				this.settings["headers"] = {
					"Content-Type": "application/json",
				}
				break;

				
			case "sendContact":
				this.settings = { ...this.defSettings };
				this.settings["method"] = "POST";
				this.settings["url"] = this.apiUrl + "Manager/Send/Email";
				this.settings["headers"] = {
					"Content-Type": "application/json",
				}
				this.settings["data"] = JSON.stringify(parameter);
				break;

			case "getFooterLinks":
				this.settings = { ...this.defSettings };
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "website/relatedLinks";
				this.settings["headers"] = {
					"Content-Type": "application/json",
				}
				break;

			default:
				return false
		}
		return this.settings
	}

	sendContact = (data = {}) => {
		let json = this.jsonRequest("sendContact", data);
		return this.petition(json, false)
	}

	getLandings = (data = []) => {
		let json = this.jsonRequest("getLandings", data);
		return this.petition(json, false)
	}

	getFooterLinks = (data = {}) => {
		let json = this.jsonRequest("getFooterLinks", data);
		return this.petition(json, false)
	}

	petition = async (json, watch = true) => {
		return new Promise(function (resolve, reject) {
			if (json) {
				axios(json)
					.then(function (response) {
						resolve(response.data);
					})
					.catch(function (error) {
						if (watch) {
							console.log(error);
						}
						reject(error);
					})
			} else {
				let response = { data: false, code: 500 }
				resolve(response);
			}
		});
	}
}


export { request };