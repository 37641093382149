<template>
    <template v-if="ready">
        <component :is="asyncComponent" :content="nextContent" :test="test" :con="con" @updateAnimes="passAnimate"></component>
    </template>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import FallbackComponent from '@/components/landingComponents/nullData.vue';

export default {
    emits:['updateAnimes'],
    props: {
        content: {
            type: String,
            default: ''
        },
        test:{
            default:false,
            type: Boolean
        },
        con: {
            default: {},
            type: Object
        }
    },
    mounted() {
        this.getComponent();
        console.log(this.content,'<<<<<')
    },
    data() {
        return {
            ready: false,
            componentSelect: 'nullData',
            nextContent: '',
        }
    },
    computed: {
        asyncComponent() {
            const loadComponent = (componentName) => {
                return defineAsyncComponent({
                    loader: async () => {
                        try {
                            const component = await import(`@/components/landingComponents/${componentName}.vue`);
                            return component;
                        } catch (error) {
                            return FallbackComponent;
                        }
                    }
                });
            };

            return loadComponent(this.componentSelect);
        }
    },
    methods: {
        passAnimate(){
            this.$emit('updateAnimes')
        },
        getComponent() {
            if (this.content !== '' && this.content !== null) {
                const parser = new DOMParser();
                const tempDom = parser.parseFromString(this.content, 'text/html');
                const temp = Array.from(tempDom.body.children);

                if (temp.length > 0) {
                    if (temp[0].localName === 'import-component') {
                        this.componentSelect = String(temp[0].getAttribute('target'));
                        this.nextContent = temp[0].innerHTML;
                    } else {
                        this.componentSelect = 'html';
                        this.nextContent = temp[0].outerHTML;
                    }
                    this.ready = true;
                } else {
                    this.nextContent = "";
                    this.componentSelect = 'nullData';
                    this.ready = false;
                }
            } else {
                this.nextContent = "";
                this.componentSelect = 'nullData';
                this.ready = false;
            }
            this.$emit('updateAnimes');
        }
    }
}
</script>