import { createRouter, createWebHistory } from 'vue-router'
import landing from '../views/HomeLanding.vue'
import landingDinamic from '../views/Landing.vue'
import downloads from '../views/Downloads.vue'
import thanks from '../views/Thanks.vue'
import userManagement from '../views/GenerateUser.vue'
import privacy from '../views/Privacy.vue'
import NotFound from '../views/404.vue'

const DEFAULT_TITLE = 'Onico EMR';

const routes = [
  {
      path: '/404', name: 'NotFound', component: NotFound,
      meta: { transition: 'instant', mode: "out-in", requiresAuth: false, title: "404" },
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
    meta: { title: '404 Not Found' }
  },
  {
    path: '/',
    name: 'home',
    component: landing,
    meta: { transition: 'scale', mode: "out-in", requiresAuth: false, title: "ONICO Health & Wellness" },
  },
  {
    path: '/l/:id',
    name: 'landing',
    component: landingDinamic,
    meta: { transition: 'scale', mode: "out-in", requiresAuth: false, title: "Landing" },
  },
  {
    path: '/t/:id',
    name: 'landingTest',
    component: landingDinamic,
    meta: { transition: 'scale', mode: "out-in", requiresAuth: false, title: "Landing" },
  },
  {
    path: '/downloads',
    name: 'downloads',
    component: downloads,
    meta: { transition: 'scale', mode: "out-in", requiresAuth: false, title: "ONICO - Descargar" },
  },
  {
    path: '/thanks',
    name: 'thanks',
    component: thanks,
    meta: { transition: 'scale', mode: "out-in", requiresAuth: false, title: "ONICO - Contacto" },
  },
  {
    path: '/umanagement',
    name: 'umanagement',
    component: userManagement,
    meta: { transition: 'scale', mode: "out-in", requiresAuth: false, title: "ONICO - Usuarios" },
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: privacy,
    meta: { transition: 'scale', mode: "out-in", requiresAuth: false, title: "ONICO - Aviso de privacidad" },
  },
  {
    path: '/appprivacy',
    name: 'privacy',
    component: privacy,
    meta: { transition: 'scale', mode: "out-in", requiresAuth: false, title: "ONICO - Aviso de privacidad" },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
      // siempre vuelve al top de la página
      return { top: 0 };
  }
})

router.beforeEach((to, from) => {
  if (to.hash){
    console.log(to.hash);
  }
})


router.afterEach((to, from) => {
  document.title = to.meta.title || DEFAULT_TITLE;
});

export default router
