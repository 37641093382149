<template lang="">
    <div id="slogan5" class="animContainer">
        <div class="w-100 container-data d-flex flex-wrap animation">
            <div class="w-100 flex-wrap text-2 text-center line-1 text-14 fw-bold font-1 mt-5 mb-5 mt-lg-0 color-13">
                Invierte en lo que Realmente<br>Importa, Tus Pacientes.
            </div>
            <div class="d-lg-flex w-100 d-none mt-5 mb-5" style="height:10px"></div>
            <div class="tableTriple w-100 d-flex mt-5 pb-3">
                <div class="col-lg-4 col-6 text-4 color-13 fw-bold font-1 text-start ps-lg-5 ps-2 d-flex align-items-center">Funciones</div>
                <div class="col-lg-4 col-3 text-4 color-13 fw-bold font-1 text-center justify-content-center d-flex align-items-center">Onico Individual</div>
                <div class="col-lg-4 col-3 text-4 color-13 fw-bold font-1 text-center justify-content-center d-flex align-items-center">Onico Equipo</div>
            </div>
            <div class="lineTable w-100 mt-2"></div>
            <div class="tableTriple w-100 d-flex py-4 align-items-stretch align-content-stretch" v-for="text in texts">
                <div class="col-lg-4 col-6 font-1 text-start d-flex flex-wrap ps-lg-5 ps-0">
                    <div class="w-100 text-15 ps-2 ps-lg-3 fw-bold color-15">{{text.title}}</div>
                    <div class="w-100 text-11 ps-2 ps-lg-3 color-13 pt-2">{{text.content}}</div>
                </div>
                <div class="col-lg-4 col-3 text-2 color-2 font-1 text-center d-flex align-items-center justify-content-center">
                    <img :src="a17" class="icon-1 check pe-none user-select-none" v-if="text.center.type == 'image' && text.center.data == 'check'">
                    <img :src="m8" class="icon-1 cross pe-none user-select-none" v-if="text.center.type == 'image' && text.center.data == 'cross'">
                    <div class="w-100 text-4 color-13" v-else-if="text.center.type == 'text'">{{text.center.data}}</div>
                </div>
                <div class="col-lg-4 col-3 text-2 color-2 font-1 text-end d-flex align-items-center justify-content-center">
                    <img :src="a17" class="icon-1 check pe-none user-select-none" v-if="text.right.type == 'image' && text.right.data == 'check'">
                    <img :src="m8" class="icon-1 cross pe-none user-select-none" v-if="text.right.type == 'image' && text.right.data == 'cross'">
                    <div class="w-100 text-4 text-center color-13" v-else-if="text.right.type == 'text'">{{text.right.data}}</div>
                </div>
            </div>
        </div>
        <div class="w-100 flex-wrap text-4 text-center line-2 font-2 mt-5 px-0">
            <pricing></pricing>
        </div>
        <div class="w-100 container-data d-flex flex-wrap animation">
            <div class="my-5 d-flex justify-content-center w-100">
                <button class="text-6 font-1 contact me-1 me-md-4" data-bs-toggle="modal" data-bs-target="#contactModal">&nbsp;&nbsp;&nbsp;&nbsp;Contáctanos&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
                <button class="text-6 font-1 schedule ms-1 ms-md-4" openScheduler="0">Agenda una demo</button>
            </div>
        </div>
    </div>
</template>
<script>

import pricing from '@/components/landingComponents/pricing.vue'

export default {
    name:"slogan5",
    components:{
        pricing
    },
    data(){
        return{
            a17:require('@/assets/images/a17.webp'),
            m8: require('@/assets/images/m8.png'),
            texts:[
                {
                    title:"Citas en línea",
                    content:"Agende y gestione citas con facilidad desde cualquier dispositivo.",
                    center:{
                        type:"image",
                        data:"check"
                    },
                    right:{
                        type:"image",
                        data:"check"
                    },
                },
                {
                    title:"Recordatorios de WhatsApp",
                    content:"Envíe automáticamente recordatorios de citas a través de WhatsApp.",
                    center:{
                        type:"image",
                        data:"check"
                    },
                    right:{
                        type:"image",
                        data:"check"
                    },
                },
                {
                    title:"Entrenador Personal de Onboarding",
                    content:"Orientación personalizada para la puesta en marcha y uso óptimo del sistema.",
                    center:{
                        type:"image",
                        data:"check"
                    },
                    right:{
                        type:"image",
                        data:"check"
                    },
                },
                {
                    title:"Sesiones Simultaneas por Dispositivo",
                    content:"Acceda a múltiples sesiones en paralelo para aumentar la productividad.",
                    center:{
                        type:"text",
                        data:"1"
                    },
                    right:{
                        type:"text",
                        data:"Ilimitadas"
                    }
                },
                {
                    title:"Auto Hospedado en propio Servidor",
                    content:"Opción de instalación local para un control total de datos y privacidad.",
                    center:{
                        type:"image",
                        data:"cross"
                    },
                    right:{
                        type:"image",
                        data:"check"
                    },
                },
                {
                    title:"Encuestas Personalizadas",
                    content:"Cree encuestas a medida para recopilar feedback valioso de los pacientes.",
                    center:{
                        type:"text",
                        data:"1"
                    },
                    right:{
                        type:"text",
                        data:"Ilimitadas"
                    }
                },
                {
                    title:"Aplicación de Escritorio",
                    content:"Acceda a una interfaz completa de gestión de pacientes desde su ordenador.",
                    center:{
                        type:"image",
                        data:"check"
                    },
                    right:{
                        type:"image",
                        data:"check"
                    },
                },
                {
                    title:"Aplicación Móvil",
                    content:"Gestión de pacientes y citas al alcance de su mano desde cualquier lugar.",
                    center:{
                        type:"image",
                        data:"check"
                    },
                    right:{
                        type:"image",
                        data:"check"
                    },
                },
                {
                    title:"Módulos Básicos Configurables",
                    content:"Ajuste los módulos de Onico para adaptarse a las necesidades específicas de su práctica.",
                    center:{
                        type:"image",
                        data:"check"
                    },
                    right:{
                        type:"image",
                        data:"check"
                    },
                },
                {
                    title:"Agenda Colaborativa",
                    content:"Coordinación y colaboración en tiempo real con todo el equipo.",
                    center:{
                        type:"image",
                        data:"check"
                    },
                    right:{
                        type:"image",
                        data:"check"
                    },
                },
                {
                    title:"Recetas y Medicamentos",
                    content:"Prescriba y gestione medicamentos de manera eficiente.",
                    center:{
                        type:"image",
                        data:"check"
                    },
                    right:{
                        type:"image",
                        data:"check"
                    },
                },
                {
                    title:"Módulo de Seguimiento",
                    content:"Mantenga un registro detallado del progreso y tratamientos de los pacientes.",
                    center:{
                        type:"image",
                        data:"check"
                    },
                    right:{
                        type:"image",
                        data:"check"
                    },
                },
                {
                    title:"Solicitudes de Estudios",
                    content:"Simplifique y gestione la solicitud y seguimiento de estudios médicos.",
                    center:{
                        type:"image",
                        data:"check"
                    },
                    right:{
                        type:"image",
                        data:"check"
                    },
                },
                {
                    title:"Nota Pre Quirúrgica",
                    content:"Información personalizada para la planificación de sus cirugías.",
                    center:{
                        type:"image",
                        data:"cross"
                    },
                    right:{
                        type:"image",
                        data:"check"
                    },
                },
                {
                    title:"Almacenamiento de Imágenes",
                    content:"Seguro y amplio espacio para el almacenamiento de imágenes médicas.",
                    center:{
                        type:"text",
                        data:"128 Gb"
                    },
                    right:{
                        type:"text",
                        data:"Ilimitado"
                    },
                },
                {
                    title:"Portal de Estudios Compartidos",
                    content:"Comparta resultados de estudios con colegas y pacientes de forma segura.",
                    center:{
                        type:"image",
                        data:"cross"
                    },
                    right:{
                        type:"image",
                        data:"check"
                    },
                },
                {
                    title:"Cuentas Por Cobrar y Punto de Venta",
                    content:"Integración de facturación y cobros para una gestión financiera eficaz.",
                    center:{
                        type:"image",
                        data:"check"
                    },
                    right:{
                        type:"image",
                        data:"check"
                    },
                },
                {
                    title:"Envío de Comprobantes por WhatsApp",
                    content:"Comunicación instantánea de recibos y facturas a través de WhatsApp.",
                    center:{
                        type:"image",
                        data:"check"
                    },
                    right:{
                        type:"image",
                        data:"check"
                    },
                },
                {
                    title:"Impresión de Tickets de Venta",
                    content:"Emisión rápida y fácil de comprobantes para los pacientes.",
                    center:{
                        type:"image",
                        data:"check"
                    },
                    right:{
                        type:"image",
                        data:"check"
                    },
                },
                {
                    title:"Portal de Auto Facturación Electrónica",
                    content:"Permita a los pacientes gestionar su facturación electrónicamente.",
                    center:{
                        type:"image",
                        data:"cross"
                    },
                    right:{
                        type:"text",
                        data:"Incluido"
                    },
                },
                {
                    title:"Soporte y Asistencia por Chat",
                    content:"Asistencia inmediata a través de chat para resolver dudas rápidamente.",
                    center:{
                        type:"image",
                        data:"check"
                    },
                    right:{
                        type:"image",
                        data:"check"
                    },
                },
                {
                    title:"Soporte y Asistencia Telefónica",
                    content:"Disponibilidad de soporte por teléfono para ayudar con cualquier consulta.",
                    center:{
                        type:"image",
                        data:"check"
                    },
                    right:{
                        type:"image",
                        data:"check"
                    },
                },
                {
                    title:"Soporte y Asistencia Presencial",
                    content:"Soporte directo en sus instalaciones para garantizar el mejor uso del sistema.",
                    center:{
                        type:"image",
                        data:"cross"
                    },
                    right:{
                        type:"image",
                        data:"check"
                    },
                },
                {
                    title:"Integración con Conmutador Telefónico",
                    content:"Conexión fluida con su sistema telefónico VoIP Efisense para una comunicación integrada.",
                    center:{
                        type:"image",
                        data:"cross"
                    },
                    right:{
                        type:"image",
                        data:"check"
                    },
                },
                {
                    title:"Integración con Línea de Emergencia",
                    content:"Enlace de llamadas de pacientes con contraseña a números de guardia.",
                    center:{
                        type:"image",
                        data:"cross"
                    },
                    right:{
                        type:"image",
                        data:"check"
                    },
                },
                {
                    title:"Integración con Sistemas CRM",
                    content:"Envío automatizado de información a su sistema de CRM Hubspot.",
                    center:{
                        type:"image",
                        data:"cross"
                    },
                    right:{
                        type:"image",
                        data:"check"
                    },
                },
                {
                    title:"Integración de Desarrollos Personalizados",
                    content:"Personalización avanzada para adaptar desarrollos especiales a los procesos únicos de su clínica.",
                    center:{
                        type:"image",
                        data:"cross"
                    },
                    right:{
                        type:"image",
                        data:"check"
                    },
                },
            ]
        }
    },
    mounted(){
    },
    methods:{
    }
}
</script>
<style lang="css" scoped>
    #slogan5{
        width:100%;
        height:auto;
        position: relative;
        overflow: hidden;
        margin-top: 15%;
    }
    .container-data{
        background-color: var(--c1);
        padding: 0 4% 0 4%;
    }
    .line-1{
        line-height: 1;
    }
    .lineTable{
        background-color: var(--secondary);
        height: 5px;
    }
    .icon-1.check{
        width: 50px;
    }
    .icon-1.cross{
        width: 45px;
    }

    @media (min-width: 0px) and (max-width: 992px) {
        .icon-1.check{
            width: 25px;
        }
        .icon-1.cross{
            width: 20px;
        }
        .line-1{
            line-height: 1.4;
        }
        .line-2{
            line-height: 1.8;
        }
    }

</style>