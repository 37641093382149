<template lang="html">
    <div ref="content" class="d-flex flex-wrap align-items-start align-content-start justify-content-center px-0 m-0">
        <top-menu></top-menu>
        <div class="col-12 d-flex flex-wrap flex-sm-nowrap align-items-stretch align-content-center justify-content-end px-md-5 px-3 position-relative mt-5">
            <div class="coverHeight"></div>
            <div class="flex-1 d-flex flex-wrap align-items-end align-content-end">
                <div class="w-100 | d-flex flex-wrap align-items-end align-content-end | px-2 px-xl-5 | mb-3 mb-sm-4 mb-md-5">
                    <div class="w-100 | font-6 fw-bold text-start t-color-2 lh-2 | t-22 t-sm-11 t-md-13 t-lg-19 t-xl-16 | pe-xxl-5">Gracias por Contactarnos</div>
                    <div class="w-100 | font-5 text-start t-color-c3 lh-3 | t-16 t-sm-9 t-md-7 t-lg-10 t-xl-8 | ps-3 | mt-3 mt-md-4">Hemos recibido tu solicitud correctamente. En breve, uno de nuestros asesores te contactará para brindarte más información y resolver todas tus dudas.</div>
                </div>
                <div class="coverHeight1 d-none d-sm-block"></div>
            </div>
            <div class="col-5 col-sm-6 col-md-5 col-lg-4 position-relative d-flex align-items-end align-content-end">
                <div class="coverHeight"></div>
                <img :src="a31" class="w-100 image">
            </div>
        </div>
        <div class="col-12 | px-1 px-sm-2 px-lg-5 | mt-4 mt-sm-5 | d-flex justify-content-center">
            <div class="col-11 | font-7 t-color-6 text-center lh-3 | t-xxl-23 t-sm-18 t-md-20 t-28">Recursos Relacionados</div>
        </div>
    
        <div class="col-12 | px-1 px-sm-2 px-lg-5 | mt-4 mb-4 mt-sm-5 mb-sm-5 | d-flex justify-content-center">
            <div class="col-10 col-xxl-9 | font-5 text-center t-color-c3 lh-4 | t-16 t-sm-10 t-md-7 t-lg-9 t-xl-8 t-xxl-7 | px-xxl-0 px-md-4">Si estás interesado en realizar nuestro proceso de onboarding personalizado, quizás estos recursos también te puedan interesar.</div>
        </div>

        <landing-cards></landing-cards>
        <pie :flinks="flinks"></pie>
    </div>
</template>
<script>
import LandingCards from '@/components/landingComponents/landingCards.vue'
import TopMenu from '@/components/home/Menu.vue'
import Pie from '@/components/home/Pie.vue'
export default {
    name:"Thanks",
    props: {
        con: {
            default: {},
            type: Object
        },
        flinks: {
            default: {},
            type: Object
        },
    },
    mounted(){
        this.$scrollInTop();
    },
    components: {
        TopMenu,
        Pie,
        LandingCards
    },
    data(){
        return{
            a31: require('@/assets/images/a31.webp'),
        }
    }
}
</script>
<style lang="css" scoped>
    .coverHeight{
        height: 44%;
        position: absolute;
        z-index: -1;
        width: 100%;
        bottom: 0;
        left: 0;
        background-color: #FFE9E8;
    }
    .coverHeight1{
        height: 44%;
        position: relative;
        z-index: -1;
        width: 100%;
        background-color: #FFE9E8;
    }
    .image{
        z-index: 2;
    }
</style>