<template lang="">
    <div id="sloganOne" class="animContainer">
        <div class="w-100 container-data pt-4 pt-sm-5 pb-1 d-flex flex-wrap justify-content-center animation">
            <div class="modal" v-bind:class="{ active: isModalActive }" @click="closeModal">
                <img :src="selectedImage" v-if="selectedImage" />
            </div>
            <div class="text-14 color-13 fw-bold font-1 line-1 mb-5 col-lg-8 col-10 mt-4 mt-md-0 mt-lg-5 mt-xxl-4">
                Menos clics, mejor servicio, automatiza la atención de tus pacientes
            </div>
            <div ref="transitions" class="col-12 d-flex flex-wrap align-items-stretch align-content-stretch px-3 px-lg-5 mt-lg-4">
                <div class="col-12 col-lg-6 px-1 px-lg-4 py-2 d-flex align-items-stretch align-content-stretch">
                    <div ref="squareData" v-bind:style="{ height: heightSquareSpace+'px' }" class="w-100 squareData ps-2 pe-3 px-lg-4 py-4 d-flex flex-wrap align-items-stretch align-content-start" @touchstart="handleTouchStart" @touchend="handleTouchEnd">
      
                            <div class="text-12 line-2 font-1 col-xl-12 col-9 text-start px-3 fw-bold d-flex align-items-center align-content-center" :class="transite ? transite : 'hidden'" v-html="transitions[indexTrans].title"></div>
                            <div class="col-3 d-xl-none d-flex align-items-center align-content-center justify-content-end">
                                <img :src="transitions[indexTrans].icon" class="icon1 user-select-none pe-none" :class="transite ? transite : 'hidden'">
                            </div>
                            <div class="w-100 d-flex flex-wrap flex-lg-nowrap mt-4 px-1 px-lg-4 pb-4">
                                <div class="col-1 d-xl-flex d-none align-items-start align-content-start">
                                    <img :src="transitions[indexTrans].icon" class="icon1 user-select-none pe-none" :class="transite ? transite : 'hidden'">
                                </div>
                                <div class="col-11 col-xl-10 d-flex flex-wrap px-4 mt-1 mt-lg-3 pb-lg-5 pb-2">
                                    <div class="text-7 line-3 font-1 w-100 text-start ps-1 fw-bold color-14" :class="transite ? transite : 'hidden'" v-html="transitions[indexTrans].subtitle"></div>
                                    <div class="text-11 line-4 font-1 w-100 text-start ps-4 mt-3 color-13" :class="transite ? transite : 'hidden'" v-html="transitions[indexTrans].content"></div>
                                </div>
                                <div class="col-12 col-lg-1 d-flex pe-4 pe-lg-0 justify-content-end justify-content-lg-center align-items-end align-content-end" @click="changeIndex(1, 'fade3', true)" role="button">
                                    <img :src="a13" class="icon2 pe-none user-select-none" >
                                </div>
                            </div>

                    </div>
                </div>
                <div class="col-12 col-lg-6 px-1 px-lg-4 py-2 d-flex align-items-md-start align-content-md-start align-items-md-stretch align-content-md-stretch" >
                    <div ref="squareDataImage" class="w-100 h-100 squareDataImage px-2 px-lg-5 d-flex align-content-center" v-bind:style="{ height: heightSquareSpaceImage+'px !important' }" @click="showModal(transitions[indexTrans].image)">
                        <img @load="assignHeight()" v-if="transite" :src="transitions[indexTrans].image" class="w-100 image1 user-select-none pe-none" :class="transite ? transite : 'hidden'" >
                    </div>
                </div>
            </div>
            <div class="text-9 color-2 font-1 line-1 mb-5 d-flex justify-content-center w-100 mt-5 px-3 px-lg-5 ">
                <button class="text-6 font-1 contact me-1 me-md-4" data-bs-toggle="modal" data-bs-target="#contactModal">&nbsp;&nbsp;&nbsp;&nbsp;Contáctanos&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
                <button class="text-6 font-1 schedule ms-1 ms-md-4" openScheduler="0">Agenda una demo</button>
                
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "sloganOne",
    data() {
        return {
            a12: require('@/assets/images/a12.png'),
            a13: require('@/assets/images/a13.webp'),
            a14: require('@/assets/images/a14.png'),
            indexTrans:0,
            activeTransite:true,
            autoTransiteAllow:true,
            touchstartX: 0,
            touchendX: 0,
            heightSquareSpace: 400,
            heightSquareSpaceImage: 400,
            transiteTime:8000,
            threshold: 50,
            transite:'fade1',
            selectedImage: null,
            isModalActive: false,
            transitions:[
                {
                    title:"Agenda en línea<br>personalizada",
                    subtitle:"Conectamos tu agenda con tus pacientes",
                    content:"Sabemos que es muy importante que los pacientes se sientan bien atendidos.<br><br>Onico cuenta con una agenda personalizada y precisa, que ayuda a organizar tu consultorio con etiquetas de motivos de consulta, tipos de pacientes y espacios pre-configurables.",
                    image:require('@/assets/images/a22.jpg'),
                    icon:require('@/assets/images/a12.png'),
                },
                {
                    title:"Comunicación automatizada",
                    subtitle:"Citas en Línea, en tu Propio Sitio Web.",
                    content:"Tus pacientes, eligen cuándo quieren verte, y lo hacen directamente en tu sitio web.<br><br>Sin depender de intermediarios ni aparecer compartiendo sitio con otros especialistas.",
                    image:require('@/assets/images/a23.jpg'),
                    icon:require('@/assets/images/a28.svg'),
                },
                {
                    title:"Menos Citas Abandonadas",
                    subtitle:"Con Recordatorios y Confirmaciones Automáticas",
                    content:"Sabemos lo importante que es recopilar sólo la información que necesitas, todos los módulos del expediente se adaptan a ti.<br><br>Contamos con módulos de diversas especialidades y módulos informativos diseñados como un traje a la medida.",
                    image:require('@/assets/images/a24.png'),
                    icon:require('@/assets/images/a27.svg'),
                },
                {
                    title:"Más Citas Recurrentes",
                    subtitle:"Preocúpate por la salud de tus pacientes",
                    content:"Asistir a consultas periódicas es crucial para la salud de tus pacientes.<br><br>Personaliza mensajes de recordatorio según la temporalidad de su especialidad o el diagnóstico de cada paciente. Esto no solo mejora su salud, sino que también incrementa la cantidad de tus citas.",
                    image:require('@/assets/images/a25.jpg'),
                    icon:require('@/assets/images/a26.svg'),
                }
            ]
        }
    },
    mounted(){
        this.assignHeight();
        window.addEventListener('resize', this.assignHeight);
        this.createObserver();
        this.transiteAuto();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.assignHeight);
        if (this.observer) {
            this.observer.disconnect();
        }
    },
    methods:{
        showModal(image) {
          this.selectedImage = image;
          this.isModalActive = true;
        },
        closeModal() {
          this.isModalActive = false;
          this.selectedImage = null;
        },
        createObserver() {
            const options = {
                root: null, // Usa el viewport del navegador como el contenedor
                rootMargin: '0px',
                threshold: 0.1 // 10% del elemento visible para activar el callback
            };
            this.observer = new IntersectionObserver(this.handleIntersect, options);
            this.observer.observe(this.$refs.transitions);
        },
        handleIntersect(entries) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.activeTransite = true;
                } else {
                    this.activeTransite = false;
                }
            });
        },
        transiteAuto(){
            setTimeout(()=>{
                if(this.autoTransiteAllow){
                    this.changeIndex();
                }
            }, this.transiteTime);
        },
        assignHeight(){
            if(window.innerWidth > 992){
                if(this.$refs.squareData.scrollHeight > this.$refs.squareDataImage.scrollHeight){
                    this.heightSquareSpace=this.$refs.squareData.scrollHeight;
                    this.heightSquareSpaceImage=this.$refs.squareData.scrollHeight;
                }else{
                    this.heightSquareSpace=this.$refs.squareDataImage.scrollHeight;
                    this.heightSquareSpaceImage=this.$refs.squareDataImage.scrollHeight;
                }
            }else{
                this.heightSquareSpace=this.$refs.squareData.scrollHeight;
                this.heightSquareSpaceImage=200;
            }
        },
        changeIndex(mode=1, fade='fade1', manual=false){
            this.transite = false;
            setTimeout(()=>{
                this.transite = fade;
                if(this.activeTransite){
                    switch(mode){
                        case 1:
                            if(this.indexTrans == (this.transitions.length - 1)){
                                this.indexTrans = 0;
                            }else{
                                this.indexTrans++;
                            }
                            break;
                        case 2:
                            if(this.indexTrans == 0){
                                this.indexTrans = (this.transitions.length - 1);
                            }else{
                                this.indexTrans--;
                            }
                            break;
                        default:
                            break;
                    }
                }
                if(manual){
                    this.autoTransiteAllow = false;
                }else{
                    this.transiteAuto();
                }
            }, 50)
        },
        handleTouchStart(event) {
            this.touchstartX = event.changedTouches[0].screenX;
        },
        handleTouchEnd(event) {
            this.touchendX = event.changedTouches[0].screenX;
            this.handleGesture();
        },
        handleGesture() {
            if (this.touchendX < this.touchstartX - this.threshold) {
                this.changeIndex(1, 'fade4', true)
            } else if (this.touchendX > this.touchstartX + this.threshold) {
                this.changeIndex(2, 'fade3', true)
            }
        },
    }
}
</script>
<style lang="css" scoped>

#sloganOne {
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
}
#sloganOne .container-data {
    background-color: var(--c1);
    margin-top: 7%;
}
.line-1{
    line-height: 1.1;
}
.line-2{
    color: var(--secondary);
    line-height: 1.2;
}
.squareData{
    border: 2px solid #D0D0D0;
    overflow: hidden;
    transition: height 0.5s ease;
}
.squareDataImage{
    border: 2px solid #D0D0D0;
    overflow: hidden;
    transition: height 0.5s ease;
}
.icon1{
    width: 100%;
    padding-top: 10px;
    object-fit: contain;
    object-position: top center;
    transform: scale(1.5);
    max-width: 70px;
}
.icon2{
    width: 50px;
}
.image1{
    object-fit: contain;
}
.icon2:hover{
    opacity: 0.9;
}

@media (min-width: 450px) and (max-width: 1067px) {
    .line-1{
        margin-top:100px !important;
    }
}

@media (min-width: 0px) and (max-width: 768px) {
    .icon1{
        width: 100%;
        padding-top: 5px;
        object-fit: contain;
        object-position: top right;
        transform: scale(1.4);
        max-width: 50px;
    }
    .icon2{
        width: 30px;
    }
    .image1{
        padding: 10px;
        object-fit: contain;
    }
    .squareData{
        min-height: 561px;
    }
    .squareDataImage{
        min-height: 208px;
    }
    .line-1{
        line-height: 1.4;
    }
    .line-2{
        line-height: 1.4;
    }
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active en versiones anteriores de Vue */ {
    opacity: 0;
}

.modal {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    justify-content: center;
    align-items: flex-end;
    z-index: 2000;
}
.modal img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.modal.active {
    display: flex;
}
</style>