<template>

        <!-- The Modal -->
        <div class="modal fade" id="contactModal" tabindex="-1" aria-labelledby="contactModalLabel" aria-hidden="true"
            ref="contactModal">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header px-md-5">
                        <h5 class="modal-title color-12 font-1 text-1" id="contactModalLabel">Contáctanos</h5>
                        <button type="button" class="closeModalVue" data-bs-dismiss="modal" aria-label="Close"><img
                                :src="m8"></button>
                    </div>
                    <div class="modal-body px-md-5">
                        <form class="px-lg-4 px-2" @submit.prevent="null">
                            <div class="row mb-3">
                                <div class="col-12 col-md-6 mb-3 mb-md-0">
                                    <label for="nombre"
                                        class="form-label text-start w-100 text-10 font-1">Nombre</label>
                                    <input type="text" class="form-control font-1 py-3 ms-1" v-model="form.nombre" ref="nombre"
                                        placeholder="Nombre" maxlength="100">
                                </div>
                                <div class="col-12 col-md-6">
                                    <label for="apellido"
                                        class="form-label text-start w-100 text-10 font-1">Apellido</label>
                                    <input type="text" class="form-control font-1 py-3 ms-1" v-model="form.apellido" ref="apellido"
                                        placeholder="Apellido" maxlength="100">
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12 col-md-6 mb-3 mb-md-0">
                                    <label for="especialidad"
                                        class="form-label text-start w-100 text-10 font-1">Especialidad</label>
                                    <input type="text" class="form-control font-1 py-3 ms-1"  v-model="form.especialidad" ref="especialidad"
                                        placeholder="Pediatra" maxlength="100">
                                </div>
                                <div class="col-12 col-md-6">
                                    <label for="email" class="form-label text-start w-100 text-10 font-1">Email</label>
                                    <input type="email" class="form-control font-1 py-3 ms-1" ref="email" v-model="form.email" 
                                        placeholder="Email" maxlength="100">
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="mensaje" class="form-label text-start w-100 text-10 font-1">Mensaje</label>
                                <textarea class="form-control text-10 font-1 ms-1" ref="mensaje" v-model="form.mensaje" rows="6"
                                    placeholder="Mensaje" maxlength="500"></textarea>
                            </div>
                            <div v-if="errorMessage" class="color-3 text-start w-100 mb-3 text-10 font-1 d-flex px-3">
                                <div>* &nbsp;</div><div class="flex-fill">{{ errorMessage }}</div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer py-3">
                        <button type="button" @click="validateForm" class="text-15 font-1 schedule">Enviar Información</button>
                    </div>
                </div>
            </div>
        </div>

</template>

<script>

import { Modal } from 'bootstrap';

export default {
    name: 'ContactModal',
    props:{
        con: {
            default: {},
            type: Object
        }
    },
    data() {
        return {
            m8: require('@/assets/images/m8.png'),
            form: {
                nombre: '',
                apellido: '',
                especialidad: '',
                email: '',
                mensaje: '',
            },
            errorMessage: '',
            modalInstance: null
        }
    },
    mounted() {
        this.modalInstance = new Modal(this.$refs.contactModal); 
    },
    beforeUnmount() {
        this.modalInstance.hide();
    },
    methods: {
        showModal() {
            this.modalInstance.show();
        },
        validateForm() {
            const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ\s]{5,200}$/;
            const emailRegex = /^(?=.{1,100}$)([^\s@]+@[^\s@]+\.[^\s@]+)$/;
            const messageRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ0-9\s,.\-]*$/;

            if (!nameRegex.test(this.form.nombre)) {
                this.errorMessage = 'El Nombre debe tener al menos 5 caracteres y solo letras con o sin acentos y espacios.';
                this.$refs.nombre.focus();
                return;
            }
            if (!nameRegex.test(this.form.apellido)) {
                this.errorMessage = 'El Apellido debe tener al menos 5 caracteres y solo letras con o sin acentos y espacios.';
                this.$refs.apellido.focus();
                return;
            }
            if (!nameRegex.test(this.form.especialidad)) {
                this.errorMessage = 'El Especialidad debe tener al menos 5 caracteres y solo letras con o sin acentos y espacios.';
                this.$refs.especialidad.focus();
                return;
            }
            if (!emailRegex.test(this.form.email)) {
                this.errorMessage = 'Email no tiene un formato correcto o excede los 100 caracteres.';
                this.$refs.email.focus();
                return;
            }
            if (!messageRegex.test(this.form.mensaje)) {
                this.errorMessage = 'El Mensaje no debe contener símbolos de etiquetas < >.';
                this.$refs.mensaje.focus();
                return;
            }

            this.errorMessage = '';
            const formData = {
                nombre: this.form.nombre,
                apellido: this.form.apellido,
                especialidad: this.form.especialidad,
                email: this.form.email,
                mensaje: this.form.mensaje
            };

            this.con.sendContact(formData).then((res) => {
                if (res.code == 200 && res.data == true) {
                     window.location.href = '/thanks';
                }
            }).catch((err) => { this.$router.replace({ name: 'NotFound' }) })
        }
    },
};
</script>

<style scoped>

#contactModal{
    height: 100vh !important;
    width: 100vw !important;;
}

.form-label {
    font-weight: 600;
}

.modal-content {
    border-radius: 30px !important;
}

.closeModalVue {
    border: 0;
    width: 35px;
    height: 25px;
    cursor: pointer;
    background-color: transparent;
}

.closeModalVue:hover {
    transform: scale(1.5);
}

.closeModalVue img {
    width: 100%;
    height: 100%;
}


input:focus,
textarea:focus {
    border-color: var(--primary) !important;
}

@media (min-width: 0px) and (max-width: 768px) {
    .modal-content {
        border-radius: 15px !important;
    }
}
</style>
