<template lang="">
    <div id="pie" class="py-5 d-flex flex-wrap justify-content-between align-items-xl-stretch align-content-xl-stretch">
        <div class="col-12 col-lg-12 col-xl-5 col-xxl-4 ps-xxl-4 ps-2 d-flex flex-wrap align-items-start align-content-start py-lg-4 mb-3 mb-xl-0">
            <div class="col-12 d-flex justify-content-start justify-content-md-center justify-content-xl-start ps-xxl-4 pe-xl-5 ps-4 mb-3">
                <div class="col-xl-12 col-md-5 d-flex justify-content-start justify-content-md-center justify-content-xl-start ps-md-4 ps-xl-0">
                    <img :src="a3" class="logo">
                </div>
            </div>
            <div class="col-12 d-flex justify-content-start justify-content-md-center justify-content-xl-start ps-xl-5 pe-xl-0 ps-md-0 pe-md-0 ps-5 pe-5 color-1 font-1 text-16 text-start text-md-center text-xl-start mb-2">
                Onico se desarrolla desde hace más de 15 años en México, por Efiense Interactive.
            </div>
            <div class="col-12 d-flex justify-content-start justify-content-md-center justify-content-xl-start ps-xl-5 pe-xl-0 ps-md-0 pe-md-0 ps-5 pe-5 color-1 font-1 text-16 text-start">
                <a href="https://efisense.com" target="_blank" class="linkAccess">efisense.com</a>&nbsp;|&nbsp;<a href="mailto:hola@efisense.com" class="linkAccess">hola@efisense.com</a>&nbsp;|&nbsp;<a href="tel:+529999306053" target="_blank" class="linkAccess">(999) 930.60.53</a>
            </div>
            <div class="col-12 d-flex flex-wrap justify-content-start justify-content-start justify-content-start ps-xl-5 pe-xl-0 ps-md-0 pe-md-0 ps-5 pe-5 align-items-center align-content-center">
                <a href="https://www.facebook.com/efisense" class="col-12 d-flex justify-content-md-center justify-content-xl-start align-items-center align-content-center ps-3 color-1 font-1 text-10 text-start py-3 mt-3 socialLink" target="_blank">
                    <img :src="a30" class="socialSvg">&nbsp;&nbsp;&nbsp;Facebook
                </a>
                <a href="https://www.instagram.com/efisense/" class="col-12 d-flex justify-content-start justify-content-md-center justify-content-xl-start align-items-center align-content-center ps-3 color-1 font-1 text-10 text-start py-2 socialLink" target="_blank">
                    <img :src="a29" class="socialSvg">&nbsp;&nbsp;&nbsp;Instagram
                </a>
            </div>
        </div>
        <div class="col-xl-7 col-xxl-8 d-flex flex-wrap justify-content-center justify-content-md-center justify-content-xxl-start align-items-start align-content-start py-lg-5 px-4 px-lg-5">

            <div v-for="(items, category, catidx) in flinks" :key="category" class="col-6 col-md-5 col-xxl-3 d-flex flex-wrap mt-3 mt-md-5 ps-0 ps-md-5 ps-xl-0" :class="catidx <= 3 ? 'mt-xxl-0' : 'mt-xxl-5'">
                <div class="col-12 text-10 color-1 fw-bold text-start mb-1 mt-4 mt-xl-0 font-1">{{ category }}</div>
                <a v-for="(item, index) in items" :key="index" class="col-12 text-16 text-start linkAccess2 ps-4 d-block mt-3 font-1" :href="item.link">{{ item.name }}</a>
            </div>

        </div>
    </div>
</template>
<script>
export default {
    name:"pie",
    props:{
        con: {
            default: {},
            type: Object
        },
        flinks: {
            default: {},
            type: Object
        },
    },
    data(){
        return{
            a3:require('@/assets/images/a3.png'),
            a29:require('@/assets/images/a29.svg'),
            a30:require('@/assets/images/a30.svg'),
        }
    },
}
</script>
<style lang="css" scoped>


    #pie{
        width:100%;
        height:auto;
        position: relative;
        overflow: hidden;
        background-color: var(--octadecagenary);
    }

    #pie .container-data{
        margin-top: 5%;
    }

    .logo{
        object-fit: contain;
        width: 80%;
        height: 80%;
        object-position: left center;
    }
    .socialSvg{
        width: 15px;
        height: 15px;
    }
    .socialLink{
        text-decoration: none;
        cursor: pointer;
    }
    .socialLink:hover{
        color: var(--secondary);
    }
    .linkAccess{
        text-decoration: none;
        color: var(--c1);
        white-space: nowrap;
    }
    .linkAccess:hover{
        color: var(--secondary);
    }
    .linkAccess2{
        text-decoration: none;
        color: var(--cuartenary);
    }
    .linkAccess2:hover{
        color: var(--secondary);
    }


</style>