<template lang="">
    <div id="sloganTwo" class="animContainer">
        <div class="w-100 container-data c1 pt-4 pt-sm-5 pb-1 animation">

            <div class="row line-1 position-relative screenListen blured" onScreen="false">
                <div class="col ovh-image justify-content-center justify-content-md-end d-flex mt-0 me-lg-5 me-0 pt-5">
                    <div class="col-6 col-xl-4 col-lg-5 me-lg-5 me-2">
                        <img class="asset pe-none user-select-none" :src="a1" alt="">
                    </div>
                </div>
            </div>

            <div class="w-100 container-data c2 pt-4 pb-5 screenListen slogan font-2" onScreen="false">

                <div class="row line-2 position-relative mt-lg-5 mt-2">
                    <div class="text-13 line-2 color-1 ps-md-4 ps-4 py-0 mt-0 mb-0 nh text-start">TU SITIO.</div>
                    <div class="text-13 line-2 color-1 ps-md-4 ps-4 py-0 mt-0 mb-0 nh text-start">TU EXPEDIENTE.</div>
                    <div class="text-13 line-2 color-1 ps-md-4 ps-4 py-0 mt-0 mb-0 nh text-start">TU AGENDA.</div>
                    <div class="text-13 line-2 color-1 ps-md-4 ps-4 py-0 mt-0 mb-0 nh text-start">TUS PACIENTES.</div>
                </div>

                <div class="row position-relative mb-md-5 mb-0 mt-3 ps-lg-4 ps-0">
                    <div class="text-2 line-3 font-1 color-1 ps-5 pe-4 py-0 mt-3 mb-0 text-md-start text-start col-lg-10 col-12">Personaliza la experiencia de cada paciente con un sistema que se adapta a tu método de trabajo, manteniendo la esencia de tu marca y servicio personalizado</div>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
export default {
    name: "sloganTwo",
    data() {
        return {
            a1: require('@/assets/images/a1.png'),
            a2: require('@/assets/images/a2.png'),
            m1: require('@/assets/images/m1.svg'),
        }
    },
    mounted(){
        
    },
    methods:{
        
    }
}
</script>
<style lang="css" scoped>
#sloganTwo {
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
}

#sloganTwo .line-1{
    z-index: 10;
}
#sloganTwo .container-data.c1 {
    background-color: var(--c1);
    margin-top: 0%;
}

#sloganTwo .container-data.c2 {
    background-color: var(--secondary);
    padding: 0 4% 0 4%;
}

#sloganTwo .container-data.c3 {
    background-color: var(--c1);
    padding: 0 4% 0 4%;
}


#sloganTwo .ovh-image {
    height: 20px;
    display: flex;
    margin-bottom: 15%;
}

#sloganTwo .ovh-image img {
    width: 100%;
    height: auto;
}

#sloganTwo .icon-1 {
    position: absolute;
    width: 45%;
    margin-left: 10%;
    height: 100%;
    object-fit: contain;
}

#sloganTwo .icon-2 {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

#sloganTwo .rounded-asset{
    border-radius: 21px;
}

.line-2{
    line-height: 1;
}
.line-2 div{
    line-height: 1.1;
}

@media (min-width: 0px) and (max-width: 992px) {

    .c1 {
        margin-top: 0% !important;
    }

    #sloganTwo .ovh-image {
        height: auto;
        background-color: var(--secondary);
        margin-bottom: 0;
    }

    .line-2{
        line-height: 1.3 !important;
    }
    .line-3{
        line-height: 1.4 !important;
    }
}


</style>