<template>
    <div class="pricing my-5 w-100 px-xl-5 px-4 py-md-5 py-2">
      <div class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 g-lg-2 g-xl-5 d-flex flex-wrap">
        <div class="col mt-2 mb-2 mb-lg-0 mt-lg-0 mt-xl-5 px-xxl-5" v-for="plan in plans" :key="plan.title">
          <div :class="['card', 'card-plan', plan.class]">

            <div class="card-header text-center w-100 position-relative d-flex align-items-center align-content-center justify-content-start py-3">
                <div v-if="plan.popular" class="popular-badge px-2 py-1 my-md-3 me-md-4 me-4 mt-3 font-6 t-7 t-sm-7 t-md-6 t-lg-5 tx-3 t-xxl-3">POPULAR</div>
                <div class="card-title d-flex align-items-center align-content-center justify-content-start t-color-19 font-6 t-25 t-sm-16 t-md-10 t-lg-10 t-xl-7 t-xxl-6 w-100 text-start py-0 ps-2 mt-4 mt-md-0">{{ plan.title }}</div>
            </div>

            <div class="card-body text-center px-xl-5 px-lg-4 px-sm-5 px-4 py-0 py-sm-4 d-flex flex-wrap align-items-stretch align-content-stretch justify-content-center pb-sm-5 pb-4">

              <div class="price col-5 col-md-6 text-start d-flex justify-content-start t-40 t-sm-35 t-md-24 t-lg-18 t-xl-15 t-xxl-15 font-7 align-items-center align-content-center">{{ plan.price }}</div>
              <div class="label-2 col-7 col-md-6 text-start d-flex justify-content-start align-items-end align-content-end t-10 t-sm-5 t-md-5 t-lg-6 t-xl-4 t-xxl-4 font-5 align-items-center align-content-center lh-3 ps-md-4 ps-4 ps-sm-0 pt-2" v-html="plan.per"></div>

              <div class="col-12 text-start d-flex justify-content-start label-1 t-12 t-sm-6 t-md-6 t-lg-8 t-xl-5 t-xxl-3 font-5 mb-lg-4">{{ plan.desc }}</div>

              <button class="btn-plan col-md-11 col-sm-8 col-12 mt-xl-5 mt-lg-2 mt-md-4 mt-sm-5 mt-4 py-2 py-lg-2 t-sm-10 t-md-8 t-lg-8 t-xl-6 font-6" openScheduler="0">Contratar Ahora</button>
              <a class="btn-contact col-md-11 col-sm-8 col-12 mt-md-2 mt-3 py-md-2 py-lg-2 t-sm-10 t-md-8 t-lg-8 t-xl-6 font-6" :href="'https://wa.me/'+number">Contactar a Ventas</a>

            </div>

            <div class="card-footer d-flex justify-content-start flex-wrap py-sm-5 py-3 px-4">
                <div class="w-100 d-flex jusitify-content-start text-start mb-3 font-6 t-15 t-sm-10 t-md-7 t-lg-8 t-xl-6 t-xxl-5">INCLUIDO:</div>
                <div class="w-100 d-flex jusitify-content-start text-start align-items-center align-content-center ps-3 mb-2 t-13 t-sm-7 t-md-5 t-lg-7 t-xl-5 t-xxl-4 font-5" v-for="item in plan.included" :key="item"><span class="circle me-2"></span>{{ item }}</div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        number:'+529996435584',
        plans: [
          {
            title: "Plan Starter",
            price: "$ 600",
            per: "Por doctor<br>Por mes",
            desc: "100 citas mensuales",
            class: "plan-starter",
            popular: false,
            included: [
              "100 Citas por Mes",
              "Recordatorios por Whatsapp",
              "Citas desde Sitio Web",
              "Marketing de Recurrencia"
            ]
          },
          {
            title: "Plan Profesional",
            price: "$ 1200",
            per: "Por doctor<br>Por mes",
            desc: "Plan ilimitado",
            class: "plan-profesional",
            popular: true,
            included: [
              "Citas ilimitadas",
              "Recordatorios por Whatsapp",
              "Citas desde Sitio Web",
              "Marketing de Recurrencia"
            ]
          },
          {
            title: "Plan Clínicas",
            price: "$ 4500",
            per: "Por Clínica<br>Por mes",
            desc: "Licencia 500 citas/mes",
            class: "plan-clinicas",
            popular: false,
            included: [
              "Usuarios ilimitados",
              "Recordatorios por Whatsapp",
              "Citas desde Sitio Web",
              "Marketing de Recurrencia"
            ]
          }
        ]
      };
    }
  };
  </script>
  
  <style scoped>
  .pricing{
    background-color: var(--secondary);
  }
  .card-plan {
    border: none;
    border-radius: 1em;
  }
  .plan-starter {
    background-color: #f8f9fa;
  }
  .plan-profesional {
    background-color: #4B4B4B;
    color: white;
  }
  .plan-clinicas {
    background-color: var(--c1);
  }

  .label-1{
    color:#606060
  }
  .plan-profesional .label-1{
    color:var(--c1)
  }
  .label-2{
    color:#a8a8a8
  }
  .plan-profesional .label-2{
    color:var(--c1)
  }
  .price{
    white-space: nowrap;
  }
  .btn-plan {
    flex-shrink: 0;
    background-color: var(--secondary);
    padding: 0.5em 1.5em 0.5em 1.5em;
    border: 4px solid #FF9198;
    border-radius: 0.7em;
    font-weight: bold;
    color:var(--c1);
    white-space: nowrap;
  }
  .btn-contact {
    color: 4px solid #A8A8A8 !important;
    flex-shrink: 0;
    background-color: var(--c1);
    padding: 0.5em 1.5em 0.5em 1.5em;
    border: 4px solid var(--primary);
    text-decoration: none;
    border-radius: 0.7em;
    font-weight: bold;
    white-space: nowrap;
  }
  .btn-contact:visited{
    color: 4px solid #A8A8A8 !important;
  }
  .circle{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--secondary);
  }
  .plan-profesional .circle{
    background-color: var(--c1);
  }
  .popular-badge {
    background-color: #dc3545;
    color: var(--c1);
    position: absolute;
    top: 0;
    right: 0;
  }
  .card-footer{
    color:#606060;
    background-color: transparent;
  }
  .plan-profesional .card-footer{
    color:var(--c1);
  }
  .card-header {
    background-color: transparent;
    border-bottom: none;
  }
  
  </style>
  