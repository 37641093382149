<template lang="html">
    <div class="d-flex text-left w-100 px-5 px-md-5 flex-wrap text">
        <span class="w-100 d-flex justify-content-center we-1 font-1 text-center mb-lg-5 mb-2"><h1>AVISO DE PRIVACIDAD</h1></span>
        <p class="mt-3 text-letter px-5">Efisense Media SA de CV (en adelante “Onico”), con domicilio en Calle 20 no 213a x 13 Vista Alegre, Mérida, Yucatán, México, es responsable del tratamiento de sus datos personales y se compromete a proteger y respetar su privacidad, cumpliendo con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (LFPDPPP) y su Reglamento, así como con cualquier otra ley o regulación aplicable en México.
</p>
<span class="w-100 d-flex justify-content-start text-start font-1"><h3>Datos personales que recabamos</h3></span>
<p class="mt-2 text-letter px-5">Onico recaba los siguientes datos personales:
* Identificación y contacto: Nombre, dirección, teléfono, correo electrónico, fecha de nacimiento, género, Registro Federal de Contribuyentes (RFC) y Clave Única de Registro de Población (CURP).
* Datos de salud: Antecedentes médicos, padecimientos, resultados de estudios clínicos y de imagen, recetas médicas, entre otros.
</p>
<span class="w-100 d-flex justify-content-start text-start font-1"><h3>Finalidades del tratamiento de los datos personales</h3></span>
<p class="mt-2 text-letter px-5">Onico tratará sus datos personales con las siguientes finalidades:
* Proveer y gestionar el servicio de expediente electrónico.
* Mantener y actualizar nuestra base de datos de pacientes y usuarios.
* Contactar al paciente o usuario para recordatorios, notificaciones o información relevante sobre el servicio.
* Mejorar la calidad y personalizar el servicio ofrecido.
* Realizar estudios estadísticos y de mercado.
* Cumplir con las obligaciones legales y regulatorias aplicables.
</p>
<span class="w-100 d-flex justify-content-start text-start font-1"><h3>Transferencia de datos personales</h3></span>
<p class="mt-2 text-letter px-5">Onico podrá transferir sus datos personales a terceros nacionales o extranjeros, siempre y cuando dichos terceros cumplan con las disposiciones legales aplicables y mantengan medidas de seguridad adecuadas. Las transferencias se realizarán únicamente con las siguientes finalidades:
* Proveer servicios de almacenamiento y procesamiento de datos en la nube.
* Cumplir con requerimientos legales, judiciales o de autoridades competentes.
* Proveer servicios de salud a solicitud del paciente o usuario.
</p>
<span class="w-100 d-flex justify-content-start text-start font-1"><h3>Ejercicio de los derechos ARCO</h3></span>
<p class="mt-2 text-letter px-5">Usted tiene derecho a acceder, rectificar, cancelar y oponerse al tratamiento de sus datos personales (Derechos ARCO). Para ejercer sus Derechos ARCO, deberá presentar una solicitud por escrito al correo electrónico [correo de contacto], con la información y documentación necesaria para acreditar su identidad y, en su caso, la de su representante legal.
</p>
<span class="w-100 d-flex justify-content-start text-start font-1"><h3>Cambios al Aviso de Privacidad</h3></span>
<p class="mt-2 text-letter px-5">Onico se reserva el derecho de modificar este Aviso de Privacidad en cualquier momento. Cualquier cambio será notificado a través de los medios establecidos en la LFPDPPP y su Reglamento, así como en nuestro sitio web y/o a través de correo electrónico.
</p>
<span class="w-100 d-flex justify-content-start text-start"></span>
<p class="mt-2 text-letter px-5 mb-5">Al utilizar el servicio de Onico Expediente Electrónico, usted manifiesta haber leído, entendido y aceptado los términos y condiciones de este Aviso de Privacidad.
        </p>
    </div>
</template>
<script>
export default {
    name:"privacy",
    props:{
        con: {
            default: {},
            type: Object
        },
        flinks: {
            default: {},
            type: Object
        },
    },
    mounted(){
        this.$scrollInTop();
    }
}
</script>
<style lang="css" scoped>
    .text{
        color:#444;
        border-radius: 16px; 
        font-weight: bold;
        margin-top: 2vw;
        white-space: pre-wrap;
    }
    .text-letter{
        width: 100%;
        overflow: hidden;
        word-wrap: break-word;
        white-space: pre-wrap;
        display: block;
        text-align: left;
        line-break: strict;
    }
</style>