<template lang="">
    <div id="downloads">
        <div class="w-100 d-flex justify-content-center container-data align-items-center align-content-stretch">
            <div class="col-12 d-flex flex-wrap aling-items-center align-content-center">
                <div class="w-100 flex-wrap text-14 text-center line-1 text-13 font-1 mt-5">
                    Descarga Onico Health
                </div>
                <div class="w-100 d-flex justify-content-center mt-2 ps-4 pb-4">
                    <div class="col-lg-7 col-10 d-flex justify-content-center flex-wrap pe-4 mt-3 pb-5">
                        <div class="text-15 color-2 ps-md-4 font-1 w-100 py-0 mt-3 mb-0 text-center">Descarga la aplicación de Onico Health para gestionar tu consultorio de manera eficiente. 
                            Organiza tus citas, registros y comunicaciones desde cualquier dispositivo.</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-100 d-flex flex-wrap justify-content-center container-data-2 align-items-center align-content-stretch py-5">
            <div class="col-11 col-lg-5 px-lg-5 mb-5">
                <div class="w-100 font-1 text-4 fw-bold text-start">Windows</div>
                <div class="w-100 font-1 text-11 text-start px-3 px-md-5 mt-4">Aquí podrás realizar todas las funciones disponibles de Onico Health, desde escritorio, versión para equipos Windows.</div>
                <div class="w-100 d-flex justify-content-center justify-content-lg-start ps-lg-5 ps-0 mt-4 mt-lg-5">
                    <a class="text-7 font-1 ms-lg-4 ms-0" href="https://ap15.efisense.net/apiOnico/Manager/Download/windows" target="_blank">Descargar</a>
                </div>
            </div>
            <div class="col-11 col-lg-5 px-lg-5 mb-5 mt-3 mt-lg-0">
                <div class="w-100 font-1 text-4 fw-bold text-start">Mac OS</div>
                <div class="w-100 font-1 text-11 text-start px-3 px-md-5 mt-4">Aquí podrás realizar todas las funciones disponibles de Onico Health, desde escritorio, versión para equipos Mac OS.</div>
                <div class="w-100 d-flex justify-content-center justify-content-lg-start ps-lg-5 ps-0 mt-4 mt-lg-5">
                    <a class="text-7 font-1 ms-lg-4 ms-0" href="https://ap15.efisense.net/apiOnico/Manager/Download/mac" target="_blank">Descargar</a>
                </div>
            </div>
            <div class="col-11 col-lg-5 px-lg-5 mb-5 mb-lg-0 mt-3 mt-lg-5">
                <div class="w-100 font-1 text-4 fw-bold text-start">iOs</div>
                <div class="w-100 font-1 text-11 text-start px-3 px-md-5 mt-4">Accede a todas las funcionalidades de Onico Health directamente desde tu iPhone o iPad, en cualquier lugar.</div>
                <div class="w-100 d-flex justify-content-center justify-content-lg-start ps-lg-5 ps-0 mt-4 mt-lg-5">
                    <a class="text-7 font-1 ms-lg-4 ms-0" href="https://apps.apple.com/mx/app/onico-health/id6483934391" target="_blank">Descargar</a>
                </div>
            </div>
            <div class="col-11 col-lg-5 px-lg-5 mt-3 mt-lg-5">
                <div class="w-100 font-1 text-4 fw-bold text-start">Android</div>
                <div class="w-100 font-1 text-11 text-start px-3 px-md-5 mt-4">Accede a todas las funcionalidades de Onico Health directamente desde tu teléfono o tablet Android, en cualquier lugar.</div>
                <div class="w-100 d-flex justify-content-center justify-content-lg-start ps-lg-5 ps-0 mt-4 mt-lg-5">
                    <a class="text-7 font-1 ms-lg-4 ms-0" href="https://ap15.efisense.net/apiOnico/apps/onicoEMR/android/5.0.0/onicoHealth-5.0.0.apk" target="_blank">Descargar</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"downloadsIndex",
    data(){
        return{
            a5:require('@/assets/images/a5.png'),
        }
    },
    mounted(){
        this.$scrollInTop();
    },
    methods:{

    }
}
</script>
<style lang="css" scoped>
    #downloads{
        width:100%;
        height:auto;
        position: relative;
        overflow: hidden;
        margin-top: 5%;
        margin-bottom: 10%;
    }
    .container-data{
        background-color: var(--quinary);
        padding: 0 4% 0 4%;
    }
    .container-data-2{
        margin-top: 2%;
        background-color: var(--c1);
        padding: 0 4% 0 4%;
    }
    .line-1{
        color: var(--secondary);
        line-height: 1;
    }
    a{
        color:var(--c5);
        background-color: var(--sexadecagenary);
        padding: 0.2em 2.5em 0.2em 2.5em;
        border: 4px solid var(--septadecagenary);
        border-radius: 0.5em; 
        font-weight: bold;
        text-decoration: none;
    }
    a:hover{
        opacity: 0.8;
        color: var(--c5);
    }
   

</style>