// src/utils/utils.js

export function xmlToJson(xml) {
	let obj = {};

	if (xml.nodeType === 1) { // Elemento
		// Atributos
		if (xml.attributes.length > 0) {
			obj["@attributes"] = {};
			for (let j = 0; j < xml.attributes.length; j++) {
				const attribute = xml.attributes.item(j);
				obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
			}
		}
	} else if (xml.nodeType === 3) { // Texto
		return xml.nodeValue.trim(); // Devolver directamente el valor de texto
	}

	// Hijos
	if (xml.hasChildNodes()) {
		for (let i = 0; i < xml.childNodes.length; i++) {
			const item = xml.childNodes.item(i);
			const nodeName = item.nodeName;
			const nodeValue = xmlToJson(item);

			if (nodeValue) { // Solo añadir si el valor no está vacío
				if (obj[nodeName] === undefined) {
					obj[nodeName] = nodeValue;
				} else {
					if (!Array.isArray(obj[nodeName])) {
						obj[nodeName] = [obj[nodeName]];
					}
					obj[nodeName].push(nodeValue);
				}
			}
		}
	}

	// Simplificar el objeto si solo tiene un #text
	if (obj['#text'] !== undefined && Object.keys(obj).length === 1) {
		return obj['#text'];
	}

	return obj;
}

export function getAbsoluteUrl(Path, domain = 'https://d1tlwjnil29m1k.cloudfront.net', ) {
	if (Path.startsWith('/img/')) {
		return Path;
	}

	try {
		new URL(Path);
		return Path;
	} catch (_) {
		return `${domain}${Path}`;
	}
}

export function getAutoUrl(Path='', domain='') {
	if (Path.startsWith('/img/')) {
		return Path;
	}

	let url = Path.includes('{{autodomainassets}}') 
	? Path.replace(/{{autodomainassets}}/g, domain)
	: Path;
	
	try {
		new URL(url);
		return url;
	} catch (_) {
		return `${domain}${Path}`;
	}
}

export function updateMetaTags(title=false, description=false, url=false, image=false) {
	try {
		if (title) {
			document.querySelector('meta[property="og:title"]').setAttribute('content', title);
		}
		if (description) {
			document.querySelector('meta[property="og:description"]').setAttribute('content', description);
		}
		if (url) {
			document.querySelector('meta[property="og:url"]').setAttribute('content', url);
			document.querySelector('link[rel="canonical"]').setAttribute('href', url);
		}
		if (image) {
			document.querySelector('meta[property="og:image"]').setAttribute('content', image);
		}
	} catch (error) {}
}