<template>
    <div class="home">

        <top-menu></top-menu>
        <downloads></downloads>
        <div class="d-xl-flex w-100 d-sm-flex mb-md-5 mb-sm-4 mt-xl-2 mt-4"
        style="height:1px"></div>
    
        <div class="col-12 | px-1 px-sm-2 px-md-5 | mt-5 mb-5 mt-md-4 mb-md-4 mt-lg-5 mb-lg-5 | d-flex | justify-content-center">
            <div class="col-11 col-lg-12 | font-8  t-color-6 text-center lh-3 | t-xxl-21 t-sm-16 t-md-18 t-23">Recursos Relacionados</div>
        </div>
        
        <div class="col-12 | px-1 px-sm-2 px-lg-5 | mt-4 mb-4 mt-sm-5 mb-sm-5 | d-flex justify-content-center">
            <div class="col-10 col-xxl-9 | font-5 text-center t-color-c3 lh-4 | t-16 t-sm-10 t-md-7 t-lg-9 t-xl-8 t-xxl-7 | px-xxl-0 px-md-4">Si quieres obtener todos los recursos necesarios para empezar con Onico Health, estos otros enlaces también pueden serte útiles.
            </div>
        </div>

        <div class="col-12 d-flex flex-wrap flex-lg-nowrap" style=" color:#fff;">
            <landing-cards :content="content"></landing-cards>
        </div>
        <pie :flinks="flinks"></pie>

    </div>
</template>

<script>
// @ is an alias to /src

import TopMenu from '@/components/home/Menu.vue'
import Pie from '@/components/home/Pie.vue'
import downloads from '@/components/downloads/index.vue'
import LandingCards from '@/components/landingComponents/landingCards.vue'

export default {
    name: 'HomeView',
    props:{
        con: {
            default: {},
            type: Object
        },
        flinks: {
            default: {},
            type: Object
        },
    },
    components: {
        TopMenu,
        downloads,
        Pie,
        LandingCards
    },
    mounted() {
        this.$scrollInTop();
    },
    data(){
        return{
            content:`<custom>
                <element>
                    <picture>{{autodomainassets}}/IR7.webp</picture>
                    <title>Ahorra tiempo con nuestras funciones especiales.
                    </title>
                    <description>Desde la gestión de citas hasta el seguimiento de pacientes, Onico Health te ofrece herramientas que simplifican tu trabajo diario.
                    </description>
                    <linktext>Aprende más sobre nuestro proceso de Onboarding
                    </linktext>
                    <linksrc>l/onboarding</linksrc>
                </element>

                <element>
                    <picture>{{autodomainassets}}/IR4.webp</picture>
                    <title>Facilita la programación de citas online para tus pacientes.
                    </title>
                    <description>Nuestra herramienta permite a tus pacientes programar citas fácilmente desde tu sitio web.
                    </description>
                    <linktext>Aprende más sobre la programación de citas online.
                    </linktext>
                    <linksrc>l/citas-sitio-web</linksrc>
                </element>

                <element>
                    <picture>{{autodomainassets}}/IR2.webp</picture>
                    <title>Conoce nuestros planes y precios: Starter, Ilimitado y Clínicas.
                    </title>
                    <description>Descubre cuál de nuestros planes se adapta mejor a tus necesidades y presupuesto.
                    </description>
                    <linktext>Aprende más sobre nuestros planes y precios.
                    </linktext>
                    <linksrc>l/precios</linksrc>
                </element>
            </custom>`
        }
    }
}
</script>

<style>
body.noScroll{
    overflow: hidden !important;
}
</style>
