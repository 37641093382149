<template>
    <div id="renderContainer">
        <login @loged="entryPanel" v-if="!loged"></login>
        <panel @loged="entryPanel" v-else></panel>
    </div>
</template>

<script>
// @ is an alias to /src
import login from '@/components/umanagement/Login.vue'
import panel from '@/components/umanagement/Panel.vue'

export default {
    name: 'userManage',
    props:{
        con: {
            default: {},
            type: Object
        },
        flinks: {
            default: {},
            type: Object
        },
    },
    components: {
        login,
        panel
    },
    data(){
        return{
            loged:false,
        }
    },
    mounted(){
        this.$scrollInTop();
    },
    methods:{
        entryPanel(){
            this.loged = true;
        }
    }
}
</script>

<style lang="css">
    #renderContainer{
        background: rgb(242,85,90);
        background: linear-gradient(0deg, rgba(242,85,90,1) 0%, rgba(229,69,104,1) 250%);
        width:100vw;
        height:100vh;
        position: relative;
        overflow: hidden;
    }
</style>
  