<template>
    <div class="home">

        <top-menu v-if="!newUser"></top-menu>
        <portada></portada>

        <slogan-one></slogan-one>
        <slogan-two></slogan-two>
        <slogan-tree></slogan-tree>
        <slogan-four></slogan-four>
        <slogan-seven></slogan-seven>
        <slogan-five></slogan-five>
        <slogan-six></slogan-six>
        <contact :con="con"></contact>
        <pie :flinks="flinks"></pie>
        <float></float>
    </div>
</template>

<script>
// @ is an alias to /src

import TopMenu from '@/components/home/Menu.vue'
import Pie from '@/components/home/Pie.vue'
import Portada from '@/components/home/Portada.vue'

import SloganOne from '@/components/home/Slogan1.vue'
import SloganTwo from '@/components/home/Slogan2.vue'
import SloganTree from '@/components/home/Slogan3.vue'
import SloganFour from '@/components/home/Slogan4.vue'
import SloganFive from '@/components/home/Slogan5.vue'
import SloganSix from '@/components/home/Slogan6.vue'
import SloganSeven from '@/components/home/Slogan7.vue'
import Contact from '@/components/home/modal/Contact.vue'
import Float from '@/components/landingComponents/whatsapp.vue'

export default {
    name: 'HomeView',
    props:{
        con: {
            default: {},
            type: Object
        },
        flinks: {
            default: {},
            type: Object
        },
    },
    components: {
        TopMenu,
        Pie,
        Portada,
        SloganOne,
        SloganTwo,
        SloganTree,
        SloganFour,
        SloganFive,
        SloganSix,
        SloganSeven,
        Contact,
        Float
    },
    data(){
        return{
            newUser:false,
            buttons:[],
        }
    },
    beforeUnmount() {
        this.removeScript();
        if (this.observer) {
            this.observer.disconnect();
        }
    },
    methods:{
        loadScript() {
            const script = document.createElement('script');
            script.src = 'https://d1tlwjnil29m1k.cloudfront.net/onico/onicoSchedulerWidget.js';
            script.id = 'widget';
            document.head.appendChild(script);
        },
        removeScript() {
            const script = document.getElementById('widget');
            if (script) {
                document.head.removeChild(script);
            }
        },
        createObserver() {
            this.hidden = document.querySelectorAll(".animContainer");
            const options = {
                root: null, // Usa el viewport del navegador como el contenedor
                rootMargin: '0%',
                threshold: 0.01 // 10% del elemento visible para activar el callback
            };
            this.observer = new IntersectionObserver(this.handleIntersect, options);

            this.hidden.forEach((entry) => {
                this.observer.observe(entry);
            });
        },
        addAnim(elm){
            elm.querySelectorAll('.animation').forEach((animNode) => {
                animNode.classList.add('increase')
            });
        },
        handleIntersect(entries) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.addAnim(entry.target)
                } else {
                }
            });
        },
    },
    mounted() {
        this.loadScript();
        this.$scrollInTop();
        this.createObserver();
    }
}
</script>

<style>
body.noScroll{
    overflow: hidden !important;
}
</style>
