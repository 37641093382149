<template lang="">
    <div id="slogan7" class="animContainer">
        <div class="w-100 container-data d-flex flex-wrap justify-content-center align-items-center align-content-center py-4 animation">
            <div class="w-100 font-1 text-12 text-center color-1 mb-2">Historias de éxito</div>
            <template v-for="(resign, rsidex) in resigns">
                <div v-if="rsidex == pos" class="resign d-flex flex-wrap justify-content-center">
                    <div class="textSquare1 d-flex justify-content-center align-items-center align-content-center" :class="transite != false ? transite : ''">
                        <img :src="resign.image" class="image1 pe-none user-select-none">
                    </div>
                    <div class="textSquare2 d-flex flex-wrap justify-content-center align-items-center align-content-center" :class="transite != false ? transite : ''">
                        <div class="w-100 text-start color-14 textLine0" v-html="resign.text">
                        </div>
                        <div class="w-100 d-flex flex-wrap mt-4 align-items-baseline align-content-baseline justify-content-start">
                            <span class="color-13 text-start textLine1">{{resign.name}}</span>
                            <span class="color-13 ms-2 text-start textLine2">{{resign.carrer}}</span>
                        </div>
                    </div>
                </div>
            </template>
            <div class="w-100 d-flex justify-content-center mt-4 pb-3 pb-xl-0" ><span v-for="(resignPpoint, rsidexPoint) in resigns" @click="setPos(rsidexPoint)" :class="pos == rsidexPoint ? 'active' : ''" class="point" role="button"></span></div>
        </div>
    </div>
</template>
<script>
export default {
    name:"slogan7",
    data(){
        return{
            pos:2,
            transite:'append',
            activeTransite:true,
            resigns:[
                {
                    image:require('@/assets/images/a19.webp'),
                    name:"Dr. Daniel Belaunde.",
                    carrer:"Oftalmólogo especialista en retina.",
                    text:"\“Onico ha demostrado ser un aliado invaluable en mi práctica profesional. Con más de 15 años trabajando con ellos, puedo afirmar que han simplificado significativamente mi trabajo como oftalmólogo.<br><br>La dedicación de Onico a la mejora continua y su capacidad para adaptarse a las necesidades del sector médico son aspectos que realmente aprecio y que han hecho de ellos un socio indispensable en mi práctica profesional.\”"
                },
                {
                    image:require('@/assets/images/a21.webp'),
                    name:"Dr. Héctor Rivero.",
                    carrer:"Especialista en endocrinología y nutrición.",
                    text:"\“La implementación de Onico Health ha sido un cambio positivo para mi consultorio. La posibilidad de gestionar citas y enviar recordatorios directamente desde mi propio sitio web ha facilitado mucho la vida de mis pacientes y la mía. Sin costos ocultos y con un sistema intuitivo, Onico es una herramienta indispensable.\”"
                },
                {
                    image:require('@/assets/images/a20.webp'),
                    name:"Lic. Miriana Claros.",
                    carrer:"Administradora Holos Diagnósticos de alta tecnología.",
                    text:"\“Onico Health ha transformado la manera en que gestionamos nuestra clínica. Su integración sin intermediarios nos permite conectarnos con nuestros pacientes de manera eficiente y mantener nuestra marca personal en cada interacción. Los recordatorios automáticos y la gestión de citas en línea han reducido significativamente las ausencias y mejorado la productividad de nuestra práctica.\”"
                }
            ]
        }
    },
    mounted(){
        this.transiteAuto();
    },
    methods:{
        setPos(pos){
            this.activeTransite = false;
            this.transite = false;
            setTimeout(()=>{
                this.transite = 'append';
                this.pos = pos;
            }, 100);
        },
        transiteAuto(){
            setTimeout(()=>{
                this.transite = false;
                
                setTimeout(()=>{
                    if(this.activeTransite){
                        this.transite = 'append';
                        if(this.pos == (this.resigns.length - 1)){
                            this.pos = 0;
                        }else{
                            this.pos++;
                        }
                        this.transiteAuto();
                    }
                }, 100);
                
            }, 10000);
        }
    }
}
</script>
<style lang="css" scoped>
    #slogan7{
        width:100%;
        height:auto;
        position: relative;
        overflow: hidden;
        margin-top: 20%;
    }
    .container-data{
        background-color: var(--secondary);
        padding: 2% 4% 3% 4%;
    }
    .line-1{
        line-height: 1;
    }
    .resign{
        background-color: var(--c1);
        font-family: 'Avenir', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }
    .point{
        width: 15px;
        height: 15px;
        background-color: var(--c1);
        border-radius: 50%;
        margin: 0 5px 0 5px;
    }
    .point.active{
        background-color: var(--c5);
    }
    .image1{
        border-radius: 50%;
        width: 70%;

    }
    .textLine1{
        white-space: nowrap;
    }
    @media (min-width: 1020px) {
        .resign{
            width: 920px;
            height: 250px;
            border-radius: 100px;
        }
        .textLine0{
            font-size: 16px;
            font-weight: 400;
            line-height: 1.55;
        }
        .textLine1{
            font-size: 20px;
            font-weight: 600;
        }
        .textLine2{
            font-size: 16px;
        }
        .textSquare1{
            width: 23%;
        }
        .textSquare2{
            width: 77%;
            padding: 10px 50px 10px 1px;
        }
    }
    @media (min-width: 650px) and (max-width: 1019px){
        .resign{
            width: 100%;
            height: auto;
            padding: 20px 70px 20px 10px;
            border-radius: 70px;
        }
        .textLine0{
            font-size: 16px;
            font-weight: 400;
            line-height: 1.55;
        }
        .textLine1{
            font-size: 20px;
            font-weight: 600;
        }
        .textLine2{
            font-size: 16px;
        }
        .textSquare1{
            width: 30%;
        }
        .textSquare2{
            width: 70%;
            padding-left: 20px;
        }
    }
    @media (min-width: 0px) and (max-width: 649px){
        .resign{
            width: 100%;
            height: auto;
            padding: 20px 20px 20px 20px;
            border-radius: 20px;
        }
        .textLine0{
            font-size: 14px;
            font-weight: 400;
            line-height: 1.55;
        }
        .textLine1{
            font-size: 16px;
            font-weight: 600;
        }
        .textLine2{
            font-size: 15px;
        }
        .textSquare1{
            width: 150px;
            margin-bottom: 20px;
        }
        .textSquare2{
            width: 100%;
        }
    }
</style>